import {
  patchStartScheduler,
  patchStopScheduler,
  refreshScheduler
} from '@attendance-web-app/services/schedulerModal.services';
import { handleErrorMessage } from '@attendance-web-app/utils/errorMessage';
import useCustomListApi from '@attendance-web-app/utils/hooks/useCustomListApi';
import { useMutation, useQueryClient } from '@tanstack/react-query';

export const useSchedulerStatusHook = (toggleHandler: () => void) => {
  const queryClient = useQueryClient();
  const {
    isLoading: isFirstLoading,
    isRefetching: refreshLoading,
    refetchQuery: refetchRefreshScheduler
  } = useCustomListApi({
    queryKey: ['schedulerRefresh'],
    getApiFn: refreshScheduler,
    enabled: false,
    params: {}
  });
  const { mutate: startScheduler } = useMutation({
    mutationFn: patchStartScheduler,
    onSuccess: data => {
      if (data) {
        queryClient.setQueriesData(
          {
            queryKey: ['schedulerStatus']
          },
          (oldQueryData: any) => {
            return {
              ...oldQueryData,
              ...data
            };
          }
        );
        toggleHandler();
      }
    },
    onError: (error: any) => {
      handleErrorMessage(error);
    }
  });
  const { mutate: stopScheduler } = useMutation({
    mutationFn: patchStopScheduler,
    onSuccess: data => {
      if (data) {
        queryClient.setQueriesData(
          {
            queryKey: ['schedulerStatus']
          },
          (oldQueryData: any) => {
            return {
              ...oldQueryData,
              ...data
            };
          }
        );
        toggleHandler();
      }
    },
    onError: (error: any) => {
      handleErrorMessage(error);
    }
  });

  return {
    refreshLoading,
    isFirstLoading,
    refetchRefreshScheduler,
    stopScheduler,
    startScheduler
  };
};
