import { useState } from 'react';
import SchedulerTitle from './SchedulerTitle';
import { daysOfWeek } from './data';

interface SchedulerDaysSelectorProps {
  start?: number;
  end?: number;
  onChangeSelectHandler: (
    option: {
      value: string;
      label: string;
    },
    type: string
  ) => void;
}
const SchedulerDaysSelector = ({ start, end, onChangeSelectHandler }: SchedulerDaysSelectorProps) => {
  const [startDay, setStartDay] = useState<number | undefined>(start);
  const [endDay, setEndDay] = useState<number | undefined>(end);
  const resetHandler = () => {
    setStartDay(undefined);
    setEndDay(undefined);
  };

  const onDayClickHandler = (item: any) => {
    const itemValue = item.value;
    if (startDay !== undefined && startDay !== itemValue && startDay < itemValue) {
      setEndDay(itemValue);
      onChangeSelectHandler(item, 'END_OF_WEEK');
    } else if (startDay === itemValue) {
      {
        setStartDay(undefined);
        setEndDay(undefined);
      }
    } else {
      onChangeSelectHandler(item, 'START_OF_WEEK');
      setStartDay(itemValue);
    }
  };

  return (
    <div className="selectDayContainer">
      <SchedulerTitle title="Run Every" subTitle="Select the days of the week on which the scheduler should run." />

      <div className="d-flex justify-content-between align-items-center w-100 flex-wrap gap-4">
        <div className="d-flex flex-wrap gap-2">
          {daysOfWeek.map((item, index) => {
            let isActive = false;

            if (startDay !== undefined) {
              isActive = startDay === item.value;
            }
            if (startDay !== undefined && endDay !== undefined) {
              isActive = startDay <= item.value && endDay >= item.value;
            }

            return (
              <div className="d-flex flex-column align-items-center" key={index}>
                <div
                  key={index}
                  className={`schedulerDay ${isActive ? 'selected' : ''}`}
                  onClick={() => onDayClickHandler(item)}
                >
                  <span>{item.label}</span>
                </div>

                {(startDay === item.value || endDay === item.value) && (
                  <div className={`pointer ${startDay === item.value ? 'start' : ''}`} />
                )}
              </div>
            );
          })}
        </div>
        <span className="reset" onClick={resetHandler}>
          Reset
        </span>
      </div>

      <div></div>
    </div>
  );
};
export default SchedulerDaysSelector;
