export interface IParams {
  name?: string;
  limit?: number | string;
  offset?: number;
  department?: string;
  isDepartmentKeyId?: boolean;
  hod?: string;
  year?: number;
  id?: string;
}
export let initialParams: IParams = {
  name: '',
  limit: 100,
  offset: 0
};

export interface ICustomListApiHookProps {
  queryKey: string[];
  getApiFn: (params: any) => any;
  enabled: boolean;
  params?: any;
  options?: any;
}
export interface ICustomPostApiHookProps {
  queryKey: string[];
  postApiFn: (params: any) => any;
  enabled: boolean;
  payload?: any;
  options?: any;
}
