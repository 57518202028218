import LoadingScreen from '@attendance-web-app/components/Common/LoadingScreen';
import Drawer from '@attendance-web-app/components/_pages/_MobileView/Drawer';
import List from '@attendance-web-app/components/_pages/_ViewList/List';
import departmentServices from '@attendance-web-app/services/department.services';
import { IDepartmentList } from '@attendance-web-app/types/department/type';
import { handleErrorMessage } from '@attendance-web-app/utils/errorMessage';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

export default function ViewList() {
  const { departmentName } = useParams();
  const [open, setOpen] = useState(false);
  const [departmentDetails, setDepartmentDetails] = useState<IDepartmentList>({
    departments: [],
    totalCount: 0,
    totalPages: 0
  });
  const [isLoading, setLoading] = useState(true);

  const handleOpen = () => {
    setOpen(true);
    document.body.style.overflowY = 'hidden';
  };

  const handleClose = () => {
    setOpen(false);
    document.body.style.overflowY = 'auto';
  };

  const fetchSpecificDepartment = async () => {
    try {
      const selecteddepartmentDetails = await departmentServices.getspecificDepartment(departmentName || '');
      setDepartmentDetails(selecteddepartmentDetails.data.data);
      setLoading(false);
    } catch (err) {
      handleErrorMessage(err);
    }
  };

  useEffect(() => {
    setLoading(true);
    fetchSpecificDepartment();
  }, []);

  if (isLoading) {
    return <LoadingScreen />;
  } else {
    return (
      <div className="main-wrapper">
        <div className="content-wrapper">
          <Drawer open={open} handleClose={handleClose} />
          <div className="content">
            <div className="container-fluid">
              <div className="row list-container">
                <List departmentDetails={departmentDetails} />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
