import AvatarName from '@attendance-web-app/components/Common/AvatarName';
import LoadingScreen from '@attendance-web-app/components/Common/LoadingScreen';
import { IDepartmentList } from '@attendance-web-app/types/department/type';
import { IEmployeeList } from '@attendance-web-app/types/employee/type';
import { Icon } from '@iconify/react';
import { useEffect, useState } from 'react';

interface IViewEmployeeListTableProps {
  departmentDetails: IDepartmentList;
  searchKeyword: string;
}

const ViewEmployeeListTable = ({ departmentDetails, searchKeyword }: IViewEmployeeListTableProps) => {
  const [isLoading, setLoading] = useState<boolean>(false);

  const [filteredEmployees, setFilteredEmployees] = useState<IEmployeeList['employee']>([]);

  useEffect(() => {
    const filtered = departmentDetails.departments[0].employees.filter(employee =>
      employee.name.toLowerCase().includes(searchKeyword.toLowerCase())
    );
    setFilteredEmployees(filtered);
  }, [departmentDetails, searchKeyword]);

  return (
    <LoadingScreen isLoading={isLoading}>
      <div className="col-12" style={{ width: '100%' }}>
        {filteredEmployees.length <= 0 ? (
          <div className="empty-message body-large-regular">No data to display</div>
        ) : (
          <div className="list-wrapper-designation">
            <div className="table-responsive department-list-table ">
              <table className="table" style={{ width: '100%' }}>
                <thead className="thead body-small-semibold">
                  <tr style={{ backgroundColor: '#F5F9FF' }}>
                    <th>EMPLOYEE ID</th>
                    <th style={{ width: '55%' }}>EMPLOYEE DETAIL</th>
                    <th>SUPERVISOR</th>
                  </tr>
                </thead>

                <tbody className="tbody">
                  {filteredEmployees?.map(employees => (
                    <tr key={employees.id}>
                      <td className="align-middle emp-id">{employees.employeeIdNo}</td>
                      <td className="empDetail">
                        <div className="d-flex">
                          <div className="empImage py-1 pe-3">
                            {employees?.profilePhoto ? (
                              <img
                                src={employees?.profilePhoto}
                                alt={employees?.name}
                                width="35px"
                                height="35px"
                                className=" round-image"
                              />
                            ) : (
                              <AvatarName name={employees.name || employees.email || ''} />
                            )}
                          </div>
                          <div className="dash-detail body-small-semibold">
                            <span className="employee-name">{employees.name}</span>
                            <div className="bottomText">
                              <span className="email">{employees.email}</span>
                              {
                                <Icon
                                  icon="material-symbols:circle"
                                  width="4px"
                                  height="4px"
                                  color="grey"
                                  style={{ margin: '0 5px' }}
                                />
                              }
                              <span className="position">{employees.designation.name}</span>
                            </div>
                          </div>
                        </div>
                      </td>

                      <td className="align-middle">
                        <span className="body-small-semibold">
                          {employees?.supervisor?.name === '' ? <div>Not assigned</div> : employees.supervisor.name}
                        </span>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div></div>
          </div>
        )}
      </div>
    </LoadingScreen>
  );
};
export default ViewEmployeeListTable;
