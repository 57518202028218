import DropdownMenuStatic from '@attendance-web-app/Design/components/DropdownMenuStatic';
import ErrorPage from '@attendance-web-app/components/Common/ErrorPage';
import LoadingScreen from '@attendance-web-app/components/Common/LoadingScreen';
import LogsDetail from '@attendance-web-app/components/_pages/_Dashboard/LogsDetail';
import Callback from '@attendance-web-app/components/_pages/_Login/Callback';
import PageWrapper from '@attendance-web-app/components/_pages/_PageWrapper';
import { useInitContext } from '@attendance-web-app/global-state/context/InitContext';
import BalanceSheet from '@attendance-web-app/pages/balanceSheet';
import Dashboard from '@attendance-web-app/pages/dashboard';
import DepartmentList from '@attendance-web-app/pages/departmentList';
import EmployeeList from '@attendance-web-app/pages/employeeList';
import Login from '@attendance-web-app/pages/login';
import Logs from '@attendance-web-app/pages/logs';
import Records from '@attendance-web-app/pages/records';
import SpendingList from '@attendance-web-app/pages/spendingList';
import VendorView from '@attendance-web-app/pages/vendorView';
import VendorWiseView from '@attendance-web-app/pages/vendorWiseView';
import ViewList from '@attendance-web-app/pages/viewList';
import { Route, Routes } from 'react-router-dom';
import AdminRoute from './AdminRoute';
import AssociateRoute from './AssociateRoute';

const Router = () => {
  const { accessToken, userDetails } = useInitContext();
  return (
    <Routes>
      <Route path="/" element={accessToken && userDetails ? <Dashboard /> : <Login />} />
      <Route path="/login" element={<Login />} />
      <Route
        path="/dashboard"
        element={
          <AssociateRoute>
            <Dashboard />
          </AssociateRoute>
        }
      />
      <Route
        path="/records/:employeeId"
        element={
          <AdminRoute>
            <PageWrapper hasSideBar={false} navBarTitle="Attendance List">
              <Records />
            </PageWrapper>
          </AdminRoute>
        }
      />
      <Route
        path="/logs"
        element={
          <AdminRoute>
            <Logs />
          </AdminRoute>
        }
      />
      <Route
        path="/logs/:logsId"
        element={
          <AdminRoute>
            <LogsDetail />
          </AdminRoute>
        }
      />
      <Route
        path="/employee-list"
        element={
          <AdminRoute>
            <EmployeeList />
          </AdminRoute>
        }
      />
      <Route
        path="/department-list"
        element={
          <AdminRoute>
            <DepartmentList />
          </AdminRoute>
        }
      />

      <Route
        path="/view-list/:departmentName"
        element={
          <AdminRoute>
            <PageWrapper hasSideBar={false} navBarTitle="Department List">
              <ViewList />
            </PageWrapper>
          </AdminRoute>
        }
      />
      <Route
        path="/spending-list"
        element={
          <AdminRoute>
            <SpendingList />
          </AdminRoute>
        }
      />
      <Route
        path="/balance-sheet"
        element={
          <AdminRoute>
            <PageWrapper>
              <BalanceSheet />
            </PageWrapper>
          </AdminRoute>
        }
      />
      <Route
        path="/vendor-list"
        element={
          <AdminRoute>
            <PageWrapper>
              <VendorWiseView />
            </PageWrapper>
          </AdminRoute>
        }
      />
      <Route
        path="/vendor-list/:vendorId"
        element={
          <AdminRoute>
            <PageWrapper hasSideBar={false} navBarTitle="Vendor's List">
              <VendorView />
            </PageWrapper>
          </AdminRoute>
        }
      />
      <Route path="/callback" element={<Callback />} />
      <Route path="/error" element={<ErrorPage status="401" />} />
      <Route path="/loading" element={<LoadingScreen />} />
      <Route path="/design/dropdown-menu" element={<DropdownMenuStatic />} />
      <Route path="/error403" element={<ErrorPage status="403" />} />
    </Routes>
  );
};

export default Router;
