import { Icons } from '@attendance-web-app/assets/Icons/Icons';
import { format } from 'date-fns';

interface SchedulerStatusItemProps {
  iconName: string;
  executedTitle: string;
  executedDate: Date;
}
const SchedulerStatusItem = ({ iconName, executedTitle, executedDate = new Date() }: SchedulerStatusItemProps) => {
  return (
    <div className="schedulerStatusItem">
      <div className="icon">{iconName === 'left' ? <Icons.rightRotateIcon /> : <Icons.leftRotateIcon />}</div>

      <div>
        <span className="lastExecutedTitle">{executedTitle}:</span>
        <div>
          {executedDate ? (
            <span className="lastExecutedItem">
              {format(new Date(executedDate), 'yyyy-MM-dd')} | {format(new Date(executedDate), 'hh:mm a')}
            </span>
          ) : (
            <span className="lastExecutedItem">N/A</span>
          )}
        </div>
      </div>
    </div>
  );
};

export default SchedulerStatusItem;
