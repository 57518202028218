import LoadingScreen from '@attendance-web-app/components/Common/LoadingScreen';
import { useAttendanceContext } from '@attendance-web-app/global-state/context/AttendanceContext';

import { Icons } from '@attendance-web-app/assets/Icons/Icons';
import AvatarName from '@attendance-web-app/components/Common/AvatarName';
import ShiftBatch from '@attendance-web-app/components/Common/ShiftBatch';
import { Add } from '@attendance-web-app/global-state/actions/attendanceAction';
import { getFormattedTimeFromDate } from '@attendance-web-app/libs/date';
import { IAttendance } from '@attendance-web-app/types/employee/type';
import { getDropOffServiceOption } from '@attendance-web-app/utils';
import { Icon } from '@iconify/react';
import { useState } from 'react';
import { Link } from 'react-router-dom';
interface IAttendanceTableProps {
  isLoading?: boolean;
}
const AttendanceTable = ({ isLoading = false }: IAttendanceTableProps) => {
  const [selectedAttendance, setSelectedAttendance] = useState<IAttendance[]>([]);
  const { attendanceState, attendanceDispatch } = useAttendanceContext();

  const handleHeaderCheckboxChange = (event: any) => {
    const isChecked = event.target.checked;
    const selectedAttendances: any = isChecked ? attendanceState.attendance : [];

    setSelectedAttendance(selectedAttendances);
    attendanceDispatch(
      Add({
        selectedAttendanceList: selectedAttendances
      })
    );
    const allRowCheckboxes = document.querySelectorAll('tbody .row-checkbox');
    allRowCheckboxes.forEach(checkbox => {
      (checkbox as HTMLInputElement).checked = isChecked;
    });
  };

  const handleRowCheckboxChange = (event: any, attendance: any) => {
    const isChecked = event.target.checked;
    if (isChecked) {
      setSelectedAttendance(prev => [...prev, attendance]);
      attendanceDispatch(
        Add({
          selectedAttendanceList: [...selectedAttendance, attendance]
        })
      );
    } else {
      setSelectedAttendance(prev => prev.filter(selectedAttendance => selectedAttendance?.id !== attendance.id));
      const filterData = selectedAttendance.filter(selectedAttendance => selectedAttendance?.id !== attendance.id);
      attendanceDispatch(
        Add({
          selectedAttendanceList: [...filterData] as any
        })
      );
    }
  };
  return (
    <LoadingScreen isLoading={isLoading}>
      <div className="col-12">
        <div className="list-wrapper mt-2">
          <div className="table-responsive attendance-table">
            <table className="table">
              <thead className="thead body-regular-semibold attendance-thead">
                <tr className="table-header">
                  {attendanceState?.attendance && attendanceState?.attendance?.length > 0 && (
                    <th className="td">
                      <input type="checkbox" name="" id="" onChange={handleHeaderCheckboxChange} />
                    </th>
                  )}
                  <th>Id</th>
                  <th>Employee Details</th>
                  <th>Shift</th>
                  <th>Department</th>
                  <th>Drop off service</th>
                  {attendanceState?.paramsValue?.period === '' ? '' : <th>Date</th>}
                  <th>Check-in Time</th>
                  <th>Check-out Time</th>
                </tr>
              </thead>

              <tbody className="tbody">
                {attendanceState?.attendance?.map(employeeAttendanceItem => (
                  <tr key={employeeAttendanceItem.id}>
                    <td className="align-middle">
                      <input
                        type="checkbox"
                        className="row-checkbox"
                        onChange={e => handleRowCheckboxChange(e, employeeAttendanceItem)}
                      />
                    </td>
                    <td className="align-middle emp-id">{employeeAttendanceItem.employee.employeeIdNo}</td>
                    <td className="empDetail">
                      <div className="d-flex">
                        <div className="empImage py-2 pe-3">
                          {employeeAttendanceItem.employee.profilePhoto ? (
                            <img
                              src={employeeAttendanceItem.employee.profilePhoto}
                              alt={employeeAttendanceItem.employee.name}
                              width="35px"
                              height="35px"
                              className="round-image"
                            />
                          ) : (
                            <AvatarName name={employeeAttendanceItem.employee.name ?? ''} />
                          )}
                        </div>
                        <div className="dash-detail">
                          <Link
                            to={`/records/${employeeAttendanceItem.employee.employeeIdNo}`}
                            className="attendance-employee-link"
                          >
                            <span className="name">{employeeAttendanceItem.employee.name}</span>
                            <div className="bottomText">
                              <span>{employeeAttendanceItem.employee.email}</span>
                              {
                                <Icon
                                  icon="material-symbols:circle"
                                  width="4px"
                                  height="4px"
                                  color="grey"
                                  style={{ margin: '0 5px' }}
                                />
                              }
                              <span>{employeeAttendanceItem.employee.designation.name}</span>
                            </div>
                          </Link>
                        </div>
                      </div>
                    </td>
                    <td className="body-small-regular align-middle ">
                      <ShiftBatch shift={employeeAttendanceItem.employee?.shift ?? 'Day'} />
                    </td>
                    <td className="align-middle">
                      <span className="body-regular">{employeeAttendanceItem.employee.department}</span>
                    </td>
                    <td
                      className="align-middle"
                      style={{
                        textAlign: 'center'
                      }}
                    >
                      <span className="body-regular align-middle">
                        {employeeAttendanceItem?.isDropOffSelected
                          ? getDropOffServiceOption(employeeAttendanceItem?.dropOffOption)
                          : '-'}
                      </span>
                    </td>
                    {attendanceState?.paramsValue?.period === '' ? (
                      ''
                    ) : (
                      <td className="body-regular align-middle">
                        {String(
                          new Date(employeeAttendanceItem.date).toLocaleDateString('en-US', {
                            weekday: 'short',
                            month: 'short',
                            day: 'numeric'
                          })
                        )}
                      </td>
                    )}
                    <td className="body-regular align-middle ">
                      {getFormattedTimeFromDate(employeeAttendanceItem.checkInConverted)}
                    </td>
                    <td className="body-regular align-middle ">
                      {getFormattedTimeFromDate(employeeAttendanceItem.checkOutConverted)}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            {attendanceState?.attendance && attendanceState?.attendance?.length <= 0 ? (
              <div className="empty-message body-large-regular mt-2 justify-content-center w-100">
                <Icons.noDataIcon />
                No Attendance List to display
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </LoadingScreen>
  );
};
export default AttendanceTable;
