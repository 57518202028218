interface SchedulerTitleProps {
  title: string;
  subTitle: string;
}
const SchedulerTitle = ({ title = 'Run Every', subTitle }: SchedulerTitleProps) => {
  return (
    <div className="pb-4">
      <div>
        <span className="runEvery">{title}</span>
      </div>
      <span className="runEverySelectDay">{subTitle}</span>
    </div>
  );
};

export default SchedulerTitle;
