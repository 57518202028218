import { SCHEDULER_API } from '@attendance-web-app/config/axios.config';
import axios from 'axios';

interface IGetSchedulerStatusResponse {
  message: string;
  data: {
    running: boolean;
    lastExecution: Date;
    runOnce: boolean;
    nextExecution: Date | null;
  };
  statusCode: number;
  error: boolean;
  status: string;
}
interface IGetSchedulerStatusResponseProps {
  message: string;
  data: {
    running: boolean;
    lastExecution: Date;
    runOnce: boolean;
    nextExecution: Date | null;
    schedulerTiming: {
      endDayOfWeek: number | null;
      endHourOfDay: number | null;
      everyMinOfHour: number | null;
      startDayOfWeek: number | null;
      startHourOfDay: number | null;
    };
  };
  statusCode: number;
  error: boolean;
  status: string;
}
interface ChangeSchedulerTimeProps {
  startDayOfWeek?: number;
  endDayOfWeek?: number;
  startHourOfDay?: number;
  endHourOfDay?: number;
  everyMinOfHour?: number;
}
export const getSchedulerStatus = async (): Promise<IGetSchedulerStatusResponseProps> => {
  try {
    const response = await axios.get(`${SCHEDULER_API}/status`);
    return response.data;
  } catch (error: any) {
    throw error;
  }
};
export const changeSchedulerTimeApi = async (
  payload: ChangeSchedulerTimeProps
): Promise<IGetSchedulerStatusResponse> => {
  try {
    const response = await axios.patch(`${SCHEDULER_API}/change-scheduler-time`, payload);
    return response.data;
  } catch (error: any) {
    throw error;
  }
};
export const patchStartScheduler = async (): Promise<IGetSchedulerStatusResponse> => {
  try {
    const response = await axios.patch(`${SCHEDULER_API}/start`);
    return response.data;
  } catch (error: any) {
    throw error;
  }
};
export const patchStopScheduler = async (): Promise<IGetSchedulerStatusResponse> => {
  try {
    const response = await axios.patch(`${SCHEDULER_API}/stop`);
    return response.data;
  } catch (error: any) {
    throw error;
  }
};
export const refreshScheduler = async () => {
  try {
    const response = await axios.get(`${SCHEDULER_API}/task-scheduler`);
    return response.data;
  } catch (error: any) {
    throw error;
  }
};
