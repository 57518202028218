import { Icons } from '@attendance-web-app/assets/Icons/Icons';
import { useState } from 'react';
import { Modal, OverlayTrigger, Table, Tooltip } from 'react-bootstrap';
import { CSVLink } from 'react-csv';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';

const modalStyle = {
  height: '92%',
  top: '8%',
  borderRadius: '5px'
};

function UploadErrorModal({ handleCloseModal, showModal, uploadErrorData, uploadedData }: any) {
  const [activeTab, setActiveTab] = useState(0);

  const csvErrorData = uploadErrorData.map((item: any) => ({
    name: item?.name,
    email: item?.email,
    mobile: item?.mobile,
    grade: item?.grade,
    department: item?.department,
    designation: item?.designation,
    employeeIdNo: item?.employeeIdNo,
    status: item?.status,
    role: item?.role,
    shift: item?.shift
  }));

  const csvUpdatedData = uploadedData.map((item: any) => ({
    name: item?.name,
    email: item?.email,
    mobile: item?.mobile,
    grade: item?.grade,
    department: item?.department,
    designation: item?.designation,
    employeeIdNo: item?.employeeIdNo,
    status: item?.status,
    role: item?.role,
    shift: item?.shift
  }));

  const ErrorTooltip = ({ errorKey, errorMessage }: any) => {
    return (
      <OverlayTrigger placement="bottom" overlay={<Tooltip>{errorMessage}</Tooltip>}>
        <span className="d-flex gap-2">
          {errorKey} <Icons.Alert height={20} width={20} className="me-1" />
        </span>
      </OverlayTrigger>
    );
  };

  return (
    <div>
      <Modal show={showModal} onHide={handleCloseModal} fullscreen style={modalStyle}>
        <Modal.Header closeButton>
          <Modal.Title>Failed Upload</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Tabs>
            <TabList className="modal-tablist mb-4">
              <Tab
                className="body-regular-semibold no-bullets"
                onClick={() => setActiveTab(0)}
                selected={activeTab === 0}
              >
                {`Upload Failed (${uploadErrorData.length})`}{' '}
              </Tab>
              <Tab
                className="body-regular-semibold no-bullets"
                onClick={() => setActiveTab(1)}
                selected={activeTab === 1}
              >
                {`Updated files (${uploadedData.length}) `}{' '}
              </Tab>
            </TabList>
            <TabPanel>
              <Table borderless hover>
                <thead className="body-regular" style={{ color: '#868686', background: '#F5F4F4' }}>
                  <tr>
                    <th className="differentiate-th p-3">EmployeeIdNo</th>
                    <th className="differentiate-th p-3">Name</th>
                    <th className="differentiate-th p-3">Email</th>
                    <th className="differentiate-th p-3">Mobile</th>
                    <th className="differentiate-th p-3">Grade</th>
                    <th className="differentiate-th p-3">Supervisor</th>
                    <th className="differentiate-th p-3">Department</th>
                    <th className="differentiate-th p-3">Designation</th>
                    <th className="differentiate-th p-3">role</th>
                    <th className="differentiate-th p-3">Shift</th>
                    <th className="p-3">Exceptional Error</th>
                  </tr>
                </thead>
                <tbody className="body-large-regular">
                  {uploadErrorData?.map((item: any) => {
                    return (
                      <tr key={item.employeeIdNo}>
                        <td className="employee-id p-3">
                          {item.error && item.error.employeeIdNo ? (
                            <span className="error">
                              <ErrorTooltip errorKey={item.employeeIdNo} errorMessage={item.error.employeeIdNo} />
                            </span>
                          ) : (
                            <span>{item.employeeIdNo}</span>
                          )}
                        </td>
                        <td className="p-3">
                          {item.error && item.error.name ? (
                            <span className="error">
                              <ErrorTooltip errorKey={item.name} errorMessage={item.error.name} />
                            </span>
                          ) : (
                            item.name
                          )}
                        </td>
                        <td className="employee-id p-3">
                          {item.error && item.error.email ? (
                            <span className="error">
                              <ErrorTooltip errorKey={item.email} errorMessage={item.error.email} />
                            </span>
                          ) : (
                            <span>{item.email}</span>
                          )}
                        </td>
                        <td className="employee-id p-3">
                          {item.error && item.error.mobile ? (
                            <span className="error">
                              <ErrorTooltip errorKey={item.mobile} errorMessage={item.error.mobile} />
                            </span>
                          ) : (
                            <span>{item.mobile}</span>
                          )}
                        </td>
                        <td className="employee-id p-3">
                          {item.error && item.error.grade ? (
                            <span className="error">
                              <ErrorTooltip errorKey={item.grade} errorMessage={item.error.grade} />
                            </span>
                          ) : (
                            <span>{item.grade}</span>
                          )}
                        </td>
                        <td className="p-3">
                          {item.error && item.error.supervisorId ? (
                            <span className="error">
                              <ErrorTooltip errorKey={item.supervisor} errorMessage={item.error.supervisorId} />
                            </span>
                          ) : (
                            item.supervisor
                          )}
                        </td>
                        <td className="p-3">
                          {item.error && item.error.department ? (
                            <span className="error">
                              <ErrorTooltip errorKey={item.department} errorMessage={item.error.department} />
                            </span>
                          ) : (
                            item.department
                          )}
                        </td>
                        <td className="p-3">
                          {item.error && item.error.designation ? (
                            <span className="error">
                              <ErrorTooltip errorKey={item.designation} errorMessage={item.error.designation} />
                            </span>
                          ) : (
                            item.designation
                          )}
                        </td>
                        <td className="p-3">
                          {item.error && item.error.role ? (
                            <span className="error">
                              <ErrorTooltip errorKey={item.role} errorMessage={item.error.role} />
                            </span>
                          ) : (
                            item.role
                          )}
                        </td>
                        <td className="employee-id p-3">
                          {item.error && item.error.exceptionalError && (
                            <span className="error">{item.error.exceptionalError}</span>
                          )}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </TabPanel>
            <TabPanel>
              <Table bordered hover>
                <thead className="body-regular" style={{ color: '#868686', background: '#F5F4F4' }}>
                  <tr>
                    <th>ID</th>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Mobile</th>
                    <th>Grade</th>
                    <th>Supervisor</th>
                    <th>Department</th>
                    <th>role</th>
                  </tr>
                </thead>
                <tbody className="body-large-regular">
                  {uploadedData.map((item: any) => (
                    <tr key={item.employeeIdNo}>
                      <td>{item?.employeeIdNo}</td>
                      <td>{item?.name}</td>
                      <td>{item?.email}</td>
                      <td>{item?.mobile}</td>
                      <td>{item?.grade}</td>
                      <td>{item?.supervisor}</td>
                      <td>{item?.department}</td>
                      <td>{item?.role}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </TabPanel>
          </Tabs>
        </Modal.Body>
        <Modal.Footer className="d-flex gap-3">
          <CSVLink
            data={activeTab === 0 ? csvErrorData : csvUpdatedData}
            className="btn-outline"
            onClick={handleCloseModal}
            filename={activeTab === 0 ? 'error_data.csv' : 'updated_data.csv'}
          >
            Download CSV
          </CSVLink>

          <button className="btn" onClick={handleCloseModal} style={{ width: '150px' }}>
            Done
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default UploadErrorModal;
