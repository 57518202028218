import { useSaveLogsContext } from '@attendance-web-app/global-state/context/LogsContext';
import { ILogsTable } from '@attendance-web-app/types/logs/type';
import { Icon } from '@iconify/react';
import { format } from 'date-fns';
import { Link } from 'react-router-dom';

const LogsTable = ({ setSelectedLogs }: ILogsTable): JSX.Element => {
  const { logsState } = useSaveLogsContext();
  // Initialize as an empty array

  const handleHeaderCheckboxChange = (event: any) => {
    const isChecked = event.target.checked;
    const selectedLogs = isChecked ? logsState.data : [];
    setSelectedLogs(selectedLogs as any);
    const allRowCheckboxes = document.querySelectorAll('tbody .row-checkbox');
    allRowCheckboxes.forEach(checkbox => {
      (checkbox as HTMLInputElement).checked = isChecked;
    });
  };

  const handleRowCheckboxChange = (event: any, log: any) => {
    const isChecked = event.target.checked;
    if (isChecked) {
      setSelectedLogs(prev => [...prev, log]);
    } else {
      setSelectedLogs(prev => prev.filter(selectedLog => selectedLog.id !== log.id));
    }
  };

  return (
    <>
      {logsState?.logsDetails?.totalCount <= 0 ? (
        <div className="empty-message body-large-regular">No logs to display</div>
      ) : (
        <div className="">
          <div className="card">
            <div className="table-responsive log-list-table " style={{ overflowY: 'auto' }}>
              <table className="table border-none">
                <thead className="thead body-regular-semibold">
                  <tr>
                    <th className="td">
                      <input type="checkbox" onChange={handleHeaderCheckboxChange} />
                    </th>
                    <th>Type</th>
                    <th>Description</th>
                    <th>Last Update</th>
                    <th>Updated by</th>

                    <th></th>
                  </tr>
                </thead>

                <tbody className="tbody">
                  {Array.isArray(logsState.data)
                    ? logsState.data.map(logs => (
                        <tr key={logs.id}>
                          <td className="align-middle py-3">
                            <input
                              type="checkbox"
                              className="row-checkbox"
                              onChange={e => handleRowCheckboxChange(e, logs)}
                            />
                          </td>
                          <td className="align-middle emp-id">{logs.actionType}</td>
                          <td className="empDetail align-middle">
                            <span className="align-middle emp-id body-regular">{logs.remarks}</span>
                          </td>
                          <td className="align-middle">
                            <span className="body-small-regular">
                              {format(new Date(logs.timeStamp), 'd MMM, yyyy')}
                            </span>{' '}
                            {
                              <Icon
                                icon="material-symbols:circle"
                                width="4px"
                                height="4px"
                                color="grey"
                                style={{ margin: '0 5px' }}
                              />
                            }
                            <span className="body-small-regular">
                              {new Date(logs.timeStamp).toLocaleTimeString([], {
                                hour: '2-digit',
                                minute: '2-digit'
                              })}
                            </span>
                          </td>
                          <td className="body-regular align-middle ">
                            {' '}
                            <span className="update-name">{logs.employee.name}</span>
                            <div className="bottomText pt-1">
                              <span>{logs.employee.email}</span>
                            </div>
                          </td>

                          <td className="body-regular align-middle status">
                            <Link to={`/logs/${logs.id}`}>
                              <span className="btn-inline">View details</span>
                            </Link>
                          </td>
                        </tr>
                      ))
                    : ''}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default LogsTable;
