import AvatarName from '@attendance-web-app/components/Common/AvatarName';
import { IEmployeeDetails } from '@attendance-web-app/types/employee/type';
import { useState } from 'react';
import { Button, Image, Modal } from 'react-bootstrap';

// Reusable Modal component
const InfoModal = ({ title, onHide, content }: any) => {
  return (
    <Modal centered show={true} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{content}</Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          onClick={onHide}
          style={{ padding: '4px 16px 8px 16px', height: '32px', width: '132px' }}
        >
          Done
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const DetailSidebar = ({ employeeRecords }: { employeeRecords: IEmployeeDetails }) => {
  const userRole = JSON.parse(localStorage.getItem('admin_user') as string)?.role.role;
  const userImg = JSON.parse(localStorage.getItem('user_token') as string)?.picture;

  const [showSupervisorModal, setShowSupervisorModal] = useState(false);
  const handleSupervisorModalClose = () => setShowSupervisorModal(false);
  const handleSupervisorModalShow = () => setShowSupervisorModal(true);

  const [showHodModal, setShowHodModal] = useState(false);
  const handleHodModalClose = () => setShowHodModal(false);
  const handleHodModalShow = () => setShowHodModal(true);

  return (
    <div className="detail-wrapper">
      <div className="img ">
        {userRole !== 'individual' ? (
          employeeRecords.profilePhoto ? (
            <img src={employeeRecords.profilePhoto} alt="employee-pic" className="employeePhoto round-image" />
          ) : (
            <AvatarName name={employeeRecords.name ?? ''} />
          )
        ) : userImg ? (
          <img src={userImg} alt="employee-pic" className="employeePhoto round-image" />
        ) : (
          <AvatarName name={employeeRecords.name ?? ''} />
        )}
      </div>
      <div className="personal-info">
        <div className="body-large-semibold">{employeeRecords.name}</div>
        <div className="position bottomText">{employeeRecords.designation.name}</div>
      </div>
      <div className="info">
        <div className="body-regular-semibold">Information</div>
        <div className="department-info">
          <div className="info-title body-regular">Email</div>
          <div className="info-text body-regular">{employeeRecords.email}</div>
        </div>
        <div className="phone-info ">
          <div className="info-title body-regular">Phone no.</div>
          <div className="info-text body-regular">{employeeRecords.mobile}</div>
        </div>
        <div className="mail-info">
          <div className="info-title body-regular">Department</div>
          <div className="info-text body-regular">{employeeRecords.department}</div>
        </div>
        <div className="mail-info">
          <div className="info-title body-regular">HOD</div>
          <div onClick={employeeRecords.HOD !== null ? handleHodModalShow : undefined} className="info-hod">
            <span className="info-hod-name body-regular">
              {employeeRecords.HOD === null ? <div style={{ textAlign: 'left' }}>-</div> : employeeRecords.HOD.name}
            </span>
          </div>
        </div>
        <div className="mail-info">
          <div className="info-title body-regular">Supervisor</div>
          <div
            onClick={employeeRecords.supervisor !== null ? handleSupervisorModalShow : undefined}
            className="info-hod"
          >
            <span className="info-hod-name body-small-regular">
              {employeeRecords.supervisor === null ? (
                <div style={{ textAlign: 'left' }}>-</div>
              ) : (
                employeeRecords.supervisor.name || employeeRecords.supervisor.email
              )}
            </span>
          </div>
        </div>
      </div>

      {/* HOD Modal */}
      {showHodModal && (
        <InfoModal
          title="HOD Detail"
          onHide={handleHodModalClose}
          content={
            <>
              <div className="d-flex pb-4 mb-4 ms-3 border-bottom">
                <div className="flex-shrink-0 mt-2">
                  {employeeRecords.HOD.profilePhoto ? (
                    <Image
                      alt={employeeRecords.HOD.email}
                      src={employeeRecords.HOD.profilePhoto}
                      className="update-image round-image"
                      rounded
                    />
                  ) : (
                    <AvatarName name={employeeRecords.HOD.name || employeeRecords.HOD.email} />
                  )}
                </div>
                <div className="flex-shrink-1 ms-3 mt-1">
                  <div>{employeeRecords.HOD.name}</div>
                  <div className="info-email">{employeeRecords.HOD.email}</div>
                </div>
              </div>
              <table className="ms-3" style={{ width: '100%' }}>
                <tbody>
                  <tr>
                    <td className="body-large-semibold" style={{ width: '30%' }}>
                      <div style={{ paddingBottom: '24px' }}>Department:</div>
                    </td>
                    <td className="ps-3 body-large-regular" style={{ width: '70%' }}>
                      <div style={{ paddingBottom: '24px' }}>{employeeRecords.HOD.department.name}</div>
                    </td>
                  </tr>
                  <tr>
                    <td className="body-large-semibold">
                      <div style={{ paddingBottom: '24px' }}>Designation:</div>
                    </td>
                    <td className="ps-3 body-large-regular">
                      <div style={{ paddingBottom: '24px' }}>{employeeRecords.HOD.designation.name}</div>
                    </td>
                  </tr>
                  <tr>
                    <td className="body-large-semibold">
                      <div style={{ paddingBottom: '24px' }}>Employee Id:</div>
                    </td>
                    <td className="ps-3 body-large-regular">
                      <div style={{ paddingBottom: '24px' }}>{employeeRecords.HOD.employeeIdNo}</div>
                    </td>
                  </tr>
                  <tr>
                    <td className="body-large-semibold">
                      <div style={{ paddingBottom: '24px' }}>Phone Number:</div>
                    </td>
                    <td className="ps-3 body-large-regular">
                      <div style={{ paddingBottom: '24px' }}>{employeeRecords.HOD.mobile}</div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </>
          }
        />
      )}

      {/* Supervisor Modal */}
      {showSupervisorModal && (
        <InfoModal
          title="Supervisor Detail"
          onHide={handleSupervisorModalClose}
          content={
            <>
              <div className="d-flex pb-4 mb-4 ms-3 border-bottom">
                <div className="flex-shrink-0 mt-2">
                  {employeeRecords.supervisor.profilePhoto ? (
                    <Image
                      alt={employeeRecords.supervisor.email}
                      src={employeeRecords.supervisor.profilePhoto}
                      className="update-image"
                      rounded
                    />
                  ) : (
                    <AvatarName name={employeeRecords.supervisor.name || employeeRecords.supervisor.email} />
                  )}
                </div>
                <div className="flex-shrink-1 ms-3 mt-1">
                  <div>{employeeRecords.supervisor.name}</div>
                  <div className="info-email">{employeeRecords.supervisor.email}</div>
                </div>
              </div>
              <table className="ms-3" style={{ width: '100%' }}>
                <tbody>
                  <tr>
                    <td className="body-large-semibold" style={{ width: '30%' }}>
                      <div style={{ paddingBottom: '24px' }}>Department:</div>
                    </td>
                    <td className="ps-3 body-large-regular" style={{ width: '70%' }}>
                      <div style={{ paddingBottom: '24px' }}>
                        {employeeRecords.supervisor?.department?.name || 'Not Assigned'}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td className="body-large-semibold">
                      <div style={{ paddingBottom: '24px' }}>Designation:</div>
                    </td>
                    <td className="ps-3 body-large-regular">
                      <div style={{ paddingBottom: '24px' }}>
                        {employeeRecords?.supervisor?.designation?.name || 'Not Assigned'}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td className="body-large-semibold">
                      <div style={{ paddingBottom: '24px' }}>Employee Id:</div>
                    </td>
                    <td className="ps-3 body-large-regular">
                      <div style={{ paddingBottom: '24px' }}>
                        {employeeRecords?.supervisor?.employeeIdNo || 'Not Assigned'}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td className="body-large-semibold">
                      <div style={{ paddingBottom: '24px' }}>Phone Number:</div>
                    </td>
                    <td className="ps-3 body-large-regular">
                      <div style={{ paddingBottom: '24px' }}>
                        {employeeRecords?.supervisor?.mobile || 'Not Asssigned'}
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </>
          }
        />
      )}
    </div>
  );
};

export default DetailSidebar;
