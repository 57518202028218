import { selectedOptionType } from '@attendance-web-app/types';
import { range } from '@attendance-web-app/utils/range';
import { months } from '@fuse-apps/shared-constants';
import { addDays, getYear, startOfWeek } from 'date-fns';
import { useEffect, useState } from 'react';
import { useAttendanceContext } from '@attendance-web-app/global-state/context/AttendanceContext';
type selectedDate = Date| null;


 const useTransactionCalendar = () => {
  const currentYear = new Date().getFullYear();
//   const {attendanceState}=useAttendanceContext();
  const defaultDate= new Date();
  const [startDate, setStartDate] = useState<selectedDate>(null);
  const [endDate, setEndDate] = useState<selectedDate>(null);
  const years = range(2013, currentYear);
  const [year, setYear] = useState(getYear(defaultDate));
  const [month, setMonth] = useState(months[defaultDate.getMonth()]);
  const [isOpen, setIsOpen] = useState(true);
  const [selectedWeekStart, setSelectedWeekStart] = useState(new Date());
  const [selectedWeekEnd, setSelectedWeekEnd] = useState(new Date());

  const handleCalendarOpen = () => {
    if (!isOpen) {
      setIsOpen(isOpen => !isOpen);
      document.querySelector('.react-datepicker__tab-loop')?.setAttribute('style', 'display:block');
    } else {
      setIsOpen(isOpen => !isOpen);
      document.querySelector('.react-datepicker__tab-loop')?.setAttribute('style', 'display:none');
    }
  };

  useEffect(() => {
    startDate &&  setMonth(months[startDate.getMonth()]);
    startDate &&  setYear(getYear(startDate));
    setIsOpen(!isOpen);
    startDate && selectWeek(startDate);
  }, [startDate]);

  const selectWeek = (date: Date) => {
    const startOfWeekDate = startOfWeek(date);
    const endOfWeekDate = addDays(startOfWeekDate, 6);
    setSelectedWeekStart(startOfWeekDate);
    setSelectedWeekEnd(endOfWeekDate);
  };

  const renderDayContents = (dayOfMonth: number, date: Date): JSX.Element => {
    let customClassName;
    if ('week' && date >= selectedWeekStart && date <= selectedWeekEnd) customClassName = 'custom-classname';

    return (
      <div className={customClassName}>
        <span>{dayOfMonth}</span>
      </div>
    );
  };

  const onStartDateChangeHandler = (date?: selectedDate) => {
    setStartDate(date ?? null);
  };

  const onEndDateChangeHandler = (date?: selectedDate) => {
    setEndDate(date ?? null);
      };

  return {
    year,
    setYear,
    years,
    month,
    setMonth,
    startDate,
    onStartDateChangeHandler,
    onEndDateChangeHandler,
    handleCalendarOpen,
    setIsOpen,
    renderDayContents,
    isOpen,
    endDate
  };
};
export default useTransactionCalendar;
