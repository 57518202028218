import { Icons } from '@attendance-web-app/assets/Icons/Icons';
import { changeNormalTimeToAmPm, checkIfOpenFor24Hour } from '@attendance-web-app/libs/date';
import { useState } from 'react';
import EditVendorModal from './EditVendorModal';

const VendorSummary = ({ vendorDetail, refetchVendor }: any) => {
  const [modalShow, setModalShow] = useState(false);

  const {
    emailAddress,
    firstName,
    lastName,
    phoneNumber,
    location,
    vendorName: vendorRestaurantName,
    locationUrl,
    openingHour,
    closingHour
  } = vendorDetail ?? {};
  const vendorName = firstName + ' ' + lastName;

  const onHide = () => {
    setModalShow(false);
  };
  return (
    <>
      <div className="vendor-summary">
        <div className="vendor-summary-subtitle">
          <div className="body-large-regular vendor-summary-header"> Profile Summary</div>
          <div onClick={() => setModalShow(true)} style={{ cursor: 'pointer' }}>
            <Icons.VendorEditIcon />{' '}
          </div>
          <EditVendorModal onHide={onHide} show={modalShow} vendorDetail={vendorDetail} refetchVendor={refetchVendor} />
        </div>
        <div className="vendor-summary-description body-small-regular">
          <div className="vendor-summary-title"> Vendor </div>
          <div className="vendor-summary-detail">{vendorRestaurantName}</div>
        </div>
        <div className="vendor-summary-description body-small-regular">
          <div className="vendor-summary-title"> Owner </div>
          <div className="vendor-summary-detail">{vendorName}</div>
        </div>
        <div className="vendor-summary-description body-small-regular">
          <div className="vendor-summary-title"> Email address </div>
          <div className="vendor-summary-detail">{emailAddress}</div>
        </div>
        <div className="vendor-summary-description body-small-regular">
          <div className="vendor-summary-title"> Contact number </div>
          <div className="vendor-summary-detail">{phoneNumber}</div>
        </div>
        <div className="vendor-summary-description body-small-regular">
          <div className="vendor-summary-title"> Location </div>
          <div className="vendor-summary-detail">{location}</div>
        </div>
        {openingHour && closingHour ? (
          <div className="vendor-summary-description body-small-regular">
            <div className="vendor-summary-title">Hour</div>
            <div className="vendor-summary-detail">
              {checkIfOpenFor24Hour(openingHour, closingHour) ? (
                'Opened for 24 Hours'
              ) : (
                <>
                  <p>
                    <span className="open">Open</span>
                    {changeNormalTimeToAmPm(openingHour)} &#9679; <span className="closes">Closes</span>{' '}
                    {changeNormalTimeToAmPm(closingHour)}
                  </p>
                </>
              )}
            </div>
          </div>
        ) : null}
        <div className="vendor-summary-description body-small-regular pb-4">
          <div className="vendor-summary-title"> Google Map URL </div>
          <div className="vendor-summary-detail">
            <a href={locationUrl}>
              <p className="text-truncate">{locationUrl}</p>
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default VendorSummary;
