import { handleErrorMessage } from '@attendance-web-app/utils/errorMessage';
import axios, { AxiosResponse } from 'axios';

interface IGetSpendingListParams {
  limit?: number;
  offset?: number;
  date: string;
  departments?: string;
  designations?: string;
  roles?: string;
  statuses?: string;
  searchText?: string;
}
interface ISpendingListPayment {
  id: number;
  transactionIdNo: number;
  vendorName: string;
  vendorId: number;
  transactionDate: string;
  amount: number;
  remarks: string;
}
export interface ISpendingTransaction {
  payment: ISpendingListPayment;
  employeeId: number;
  employeeName: string;
  profilePhoto: string;
  email: string;
  designation: string;
  department: string;
  isChecked?: boolean;
}

export interface IGetSpendingListResponse {
  transactions: ISpendingTransaction[];
  total: number;
}

export const getSpendingList = async (
  params?: IGetSpendingListParams
): Promise<AxiosResponse<IGetSpendingListResponse, Error>> => {
  try {
    const response = await axios.get('/transaction/particular-day', {
      params: {
        ...params,
        searchText: params?.searchText ?? '',
        limit: params?.limit ?? 10
      }
    });
    return response.data;
  } catch (error) {
    handleErrorMessage(error);
    throw error;
  }
};
