import { Icons } from '@attendance-web-app/assets/Icons/Icons';
import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useSchedulerStatusHook } from '../hooks/useSchedulerStatusHook';
import { SchedulerBatch } from './ShiftBatch';
interface ICustomModal {
  modalTitle: string;
  children: React.ReactNode;
  footerComponent: React.ReactNode;
  showModal?: boolean;
  handleHideModal: () => void;
  handleMenuOpen?: () => void;

  schedulerRunning?: boolean;
}
const SchedulerModal = ({
  modalTitle,
  footerComponent,
  showModal,
  handleHideModal,
  schedulerRunning,
  children,
  ...props
}: ICustomModal) => {
  const [isToggled, setIsToggled] = useState<boolean | undefined>(schedulerRunning);
  const toggleHandler = () => {
    setIsToggled(prev => !prev);
  };
  const { refetchRefreshScheduler, refreshLoading, isFirstLoading, startScheduler, stopScheduler } =
    useSchedulerStatusHook(toggleHandler);

  const onNotificationClickHandler = () => {};

  const onToggleHandler = (e: any) => {
    e.preventDefault();

    if (isToggled) {
      stopScheduler();
    } else {
      startScheduler();
    }
  };

  useEffect(() => {
    setIsToggled(schedulerRunning);
  }, [schedulerRunning]);
  return (
    <Modal
      className="scheduler-modal"
      show={showModal}
      fullscreen={true}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      onHide={handleHideModal}
      animation={false}
      onExit={props?.handleMenuOpen}
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter" className="h4-semibold w-100">
          <div className="d-flex justify-content-between w-100">
            <div>
              {modalTitle}
              <span
                style={{
                  paddingLeft: 10
                }}
              >
                <Icons.refreshIcon
                  height={21}
                  width={21}
                  onClick={refetchRefreshScheduler}
                  style={{ cursor: 'pointer' }}
                  className={`${isFirstLoading || refreshLoading ? 'loading' : ''}`}
                />
              </span>
            </div>

            <div className="d-flex align-items-center">
              <SchedulerBatch title={schedulerRunning ? 'Running' : 'Stopped'} />
              <div className="divider" />
              <div onClick={onToggleHandler} className={`toggleSwitch ${isToggled ? 'on' : ''}`}>
                <Icons.thumbIcon height={21} width={21} className={`thumb ${isToggled ? 'on' : ''}`} />
              </div>
            </div>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>{children}</Modal.Body>
      <Modal.Footer>{footerComponent}</Modal.Footer>
    </Modal>
  );
};

export default SchedulerModal;
