import { SetSearch } from '@attendance-web-app/global-state/actions/filtersAction';
import { loginSuccess } from '@attendance-web-app/global-state/actions/initAction';
import { useFiltersContext } from '@attendance-web-app/global-state/context/FiltersContext';
import { useInitContext } from '@attendance-web-app/global-state/context/InitContext';
import removeAuthToken from '@attendance-web-app/services/removeAuthHeader';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useCloseAbsoluteDiv } from '../Common/hooks/useCloseAbsoluteDiv';
import { useSchedulerModal } from './useSchedulerModalHook';

export const useNavbarMainHook = () => {
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const [isSchedulerOpen, setIsSchedulerOpen] = useState<boolean>(false);
  const navigate = useNavigate();
  const admin_user =
    window.localStorage.getItem('admin_user') !== 'undefined' ? window.localStorage.getItem('admin_user') : null;
  const adminUser = JSON.parse(localStorage.getItem('user_token') as string);
  const userDetails = JSON.parse(admin_user as string);

  const [userRole, setUserRole] = useState('');
  const { sidebarDispatch } = useInitContext();
  const { filtersDispatch } = useFiltersContext();

  const menuRef = useRef<HTMLDivElement>(null);

  const handleMenuToggle = () => {
    setIsMenuOpen((prev: boolean) => !prev);
  };

  const signOut = () => {
    document.cookie.split(';').forEach(c => {
      document.cookie = c.replace(/^ +/, '').replace(/=.*/, '=;expires=' + new Date().toUTCString() + ';path=/');
    });
    sessionStorage.clear();
    sidebarDispatch(
      loginSuccess({
        accessToken: '',
        userDetails: null
      })
    );
    localStorage.clear();
    removeAuthToken();
    window.AUTHENTICATED = false;
    filtersDispatch(SetSearch(''));
    navigate('/login');
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
      setIsMenuOpen(false);
    }
  };

  useEffect(() => {
    if (localStorage.getItem('admin_user')) {
      setUserRole(userDetails?.designation?.name);
    }
  }, [userRole]);

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);
  const onClose = () => {
    setIsMenuOpen(false);
  };
  const { dropdownRef } = useCloseAbsoluteDiv(onClose);
  const onNotificationClickHandler = () => {};
  const onSchedulerClickHandler = () => {
    setIsSchedulerOpen(prev => !prev);
  };
  const { statusData } = useSchedulerModal(onSchedulerClickHandler);

  return {
    navigate,
    adminUser,
    admin_user,
    handleMenuToggle,
    userRole,
    isMenuOpen,
    signOut,
    userDetails,
    dropdownRef,
    onNotificationClickHandler,
    onSchedulerClickHandler,
    isSchedulerOpen,
    statusData
  };
};
