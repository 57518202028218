export const hoursOfDay = [
  { label: '7:00 am', value: '7' },
  { label: '8:00 am', value: '8' },
  { label: '9:00 am', value: '9' },
  { label: '10:00 am', value: '10' },
  { label: '11:00 am', value: '11' },
  { label: '12:00 pm', value: '12' },
  { label: '1:00 pm', value: '13' },
  { label: '2:00 pm', value: '14' },
  { label: '3:00 pm', value: '15' },
  { label: '4:00 pm', value: '16' },
  { label: '5:00 pm', value: '17' },
  { label: '6:00 pm', value: '18' },
  { label: '7:00 pm', value: '19' },
  { label: '8:00 pm', value: '20' },
  { label: '9:00 pm', value: '21' },
  { label: '10:00 pm', value: '22' },
  { label: '11:00 pm', value: '23' }
];
export const minutesInterval = [
  { label: '5 minutes', value: '5' },
  { label: '10 minutes', value: '10' },
  { label: '15 minutes', value: '15' },
  { label: '20 minutes', value: '20' },
  { label: '25 minutes', value: '25' },
  { label: '30 minutes', value: '30' },
  { label: '35 minutes', value: '35' },
  { label: '40 minutes', value: '40' },
  { label: '45 minutes', value: '45' },
  { label: '50 minutes', value: '50' },
  { label: '55 minutes', value: '55' },
  { label: '59 minutes', value: '59' }
];

export const daysOfWeek = [
  { label: 'Mon', value: 1 },
  { label: 'Tue', value: 2 },
  { label: 'Wed', value: 3 },
  { label: 'Thu', value: 4 },
  { label: 'Fri', value: 5 },
  { label: 'Sat', value: 6 },
  { label: 'Sun', value: 0 }
];
