import { IDesignationArchiveModalProps } from '@attendance-web-app/types/designation/type';
import { Alert, Button, Modal } from 'react-bootstrap';

const DesignationArchiveModal = ({
  customSize,
  show,
  onHide,
  designation,
  handleArchive,
  archiveError,
  setArchiveError
}: IDesignationArchiveModalProps) => {
  const size = customSize === 'md' ? undefined : customSize;

  const handleClose = () => {
    onHide();
    setArchiveError('');
  };

  return (
    <Modal show={show} size={size} onHide={handleClose} aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter" className="h4-semibold">
          Archive Designation
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <span className="body-regular ">
          Are you sure you want to <span>{designation.isArchived ? 'unarchive' : 'archive'}</span>{' '}
          <span className="bold">{designation.name}</span> ?
        </span>
        {archiveError ? (
          <div className="archiveAlert">
            <Alert
              variant={'warning'}
              dismissible={true}
              onClose={() => {
                setArchiveError('');
              }}
              className="custom-alert"
            >
              {archiveError}
            </Alert>
          </div>
        ) : null}
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="btn-tertiary"
          onClick={() => {
            onHide();
            handleClose();
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={() => {
            if (archiveError?.length !== 0) onHide();
            handleArchive(designation);
          }}
        >
          {designation.isArchived ? 'Unarchive Designation' : 'Archive Designation'}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DesignationArchiveModal;
