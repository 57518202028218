import { initSuccess } from '@attendance-web-app/global-state/actions/initAction';
import { useInitContext } from '@attendance-web-app/global-state/context/InitContext';
import { SyntheticEvent, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { returnSidebarItemTitleFromRoute, returnSidebarSubItemTitleFromRoute } from '../Common/commonData';

export const useSidebarMainHook = () => {
  const { sidebarState, sidebarDispatch } = useInitContext();
  const navigate = useNavigate();
  const location = useLocation();
  const [onHover, setOnHover] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(sidebarState?.sidebarOpen ?? true);
  const [activeSidebar, setActiveSidebar] = useState(returnSidebarItemTitleFromRoute(location.pathname));
  const [activeSidebarSubItem, setActiveSidebarSubItem] = useState(
    returnSidebarSubItemTitleFromRoute(location.pathname)
  );
  const onClose = () => {
    if (!sidebarState?.sidebarOpen) {
      setActiveSidebar('');
    }
  };
  const sidebarToggleHandler = () => {
    setIsSidebarOpen(prev => !prev);
    sidebarDispatch(
      initSuccess({
        sidebarOpen: !isSidebarOpen
      })
    );
  };

  const sidebarItemClickHandler = (title: string, isTrue: boolean, route: string | null) => () => {
    setActiveSidebar(prev => {
      if (prev === title && isTrue) {
        return '';
      }
      return title;
    });
    if (!isTrue && route) {
      navigate(route);
    }
  };

  const sidebarSubItemClickHandler = (title: string, route: string) => () => {
    if (route) {
      navigate(route);
    }
    setActiveSidebarSubItem(_ => {
      return title;
    });
  };

  const sideBarClose = !isSidebarOpen ? 'closed' : '';
  const onMouseEnterAndLeave = (event: SyntheticEvent, item: any) => {
    if (item.child.length > 0 && event.type === 'mouseenter') {
      setOnHover(item.title);
    } else {
      setOnHover('');
    }
  };

  const [mobileSidebar, setMobileSidebar] = useState(false);
  const onClickHandler = () => {
    setIsSidebarOpen(true);
    sidebarDispatch(
      initSuccess({
        sidebarOpen: true
      })
    );
    setMobileSidebar(prev => !prev);
  };

  return {
    onClose,
    sideBarClose,
    isSidebarOpen,
    sidebarToggleHandler,
    activeSidebar,
    onMouseEnterAndLeave,
    sidebarItemClickHandler,
    sidebarSubItemClickHandler,
    activeSidebarSubItem,
    mobileSidebar,
    onClickHandler
  };
};
