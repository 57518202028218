import { Icons } from '@attendance-web-app/assets/Icons/Icons';
import AvatarName from '@attendance-web-app/components/Common/AvatarName';
import LoadingScreen from '@attendance-web-app/components/Common/LoadingScreen';
import { SetViewType } from '@attendance-web-app/global-state/actions/viewAction';
import { useViewListContext } from '@attendance-web-app/global-state/context/ViewContext';
import { IDepartmentListTableProps } from '@attendance-web-app/types/department/type';
import React, { useState } from 'react';
import { Badge, Dropdown, DropdownButton } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import ArchivedModal from './ArchivedModal';
import EditDepartmentModal from './EditDepartmentModal';

const DepartmentListTable = ({ departmentListState, fetchDepartmentData, isLoading }: IDepartmentListTableProps) => {
  const navigate = useNavigate();
  const [modalShow, setModalShow] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const [selectedId, setSelectedId] = useState<number>();
  const [modalEditShow, setModalEditShow] = React.useState(false);
  const [archiveError, setArchiveError] = useState<string>('');

  const { viewListActionDispatch } = useViewListContext();

  const handleOpen = (id: number) => {
    setSelectedId(id);
    setIsMenuOpen(!isMenuOpen);
    setModalShow(false);
  };

  return (
    <LoadingScreen isLoading={isLoading}>
      <div className="col-12">
        {departmentListState?.departments.length <= 0 ? (
          <div className="empty-message body-large-regular">No data to display</div>
        ) : (
          <div className="list-wrapper">
            <div className="table-responsive department-list-table department-table">
              <table className="table">
                <thead className="thead department-thead">
                  <tr className="department-header">
                    <th className="h5-semibold-uppercase">Dept .ID</th>
                    <th className="h5-semibold-uppercase ">Department </th>
                    <th className="h5-semibold-uppercase ps-0">HOD</th>
                    <th className="h5-semibold-uppercase">Total employees</th>
                  </tr>
                </thead>

                <tbody className="tbody ">
                  {departmentListState?.departments?.map(department => (
                    <tr key={department.id}>
                      <td className="align-middle body-small-regular  ">{department.departmentIdNo}</td>
                      {department.isActive === true ? (
                        <td
                          className="align-middle"
                          onClick={() => {
                            viewListActionDispatch(SetViewType(0));
                            navigate(`/view-list/${department.name}`);
                          }}
                          style={{ cursor: 'pointer' }}
                        >
                          <span className="body-small-regular department-list-text">{department.name}</span>
                        </td>
                      ) : (
                        <td className="align-middle">
                          <span className="body-small-regular archive-name">{department.name}</span>
                        </td>
                      )}

                      <td className="empDetail align-middle ps-0" style={{ width: '425px' }}>
                        {department.hod ? (
                          <div className="d-flex align-items-center">
                            <div className="empImage py-2 pe-3">
                              {department?.hod?.employee?.profilePhoto ? (
                                <img
                                  src={department.hod?.employee?.profilePhoto}
                                  alt="name"
                                  width="35px"
                                  height="35px"
                                  className="round-image"
                                />
                              ) : (
                                <AvatarName name={department.hod?.employee?.name || department.hod?.employee?.email} />
                              )}
                            </div>
                            <div className="">
                              <span className="employee-name ">{department.hod?.employee?.name}</span>
                              <div className="bottomText">
                                <span className="email">{department.hod?.employee?.email}</span>
                                <div>
                                  {/* {
                                    <Icon
                                      icon="material-symbols:circle"
                                      width="4px"
                                      height="4px"
                                      color="grey"
                                      style={{ margin: '0 5px' }}
                                    />
                                  } */}
                                  <span className="position">{department.hod?.employee?.designation?.name}</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div className="body-small-regular align-middle pt-1">Not Assigned</div>
                        )}
                      </td>

                      <td className="align-middle">
                        <div className="d-flex align-middle department-employee-th  ">
                          <div className=" align-middle body-small-regular department-list-text pt-3">
                            {department.employeeCount}
                          </div>
                          <div className="d-flex justify-content-around department-option" style={{ gap: '30px' }}>
                            {!department.isActive ? (
                              <Badge className="archive-badge body-small-regular">Archived</Badge>
                            ) : (
                              <div className="archive-badge" style={{ visibility: 'hidden' }}></div>
                            )}
                            <div
                              className="department-dropdown-options d-flex body-small-regular"
                              style={{ padding: '10px 0px' }}
                            >
                              <DropdownButton
                                drop="start"
                                title={<Icons.OptionIcon height={20} onClick={() => handleOpen(department.id)} />}
                                className="options-icon-department"
                              >
                                {selectedId === department.id && (
                                  <div className="box-shadow-1">
                                    <Dropdown.Item
                                      className={`dropdown-edit-department body-regular ${
                                        department.isActive === true ? 'editDepartment' : 'disable-department'
                                      }`}
                                      onClick={() => !department.isActive === false && setModalEditShow(true)}
                                      disabled={!department.isActive}
                                    >
                                      {<Icons.EditIcon className="dropdown-icon" />}Edit department
                                    </Dropdown.Item>
                                    <EditDepartmentModal
                                      id={department.id}
                                      departmentIdNo={department.departmentIdNo}
                                      name={department.name}
                                      selectedDepartment={department}
                                      hod={department?.hod}
                                      fetchDepartmentData={fetchDepartmentData}
                                      show={modalEditShow}
                                      onHide={() => setModalEditShow(false)}
                                      setIsMenuOpen={setIsMenuOpen}
                                    />
                                    <Dropdown.Item onClick={() => setModalShow(true)} className="body-regular">
                                      {<Icons.ArchiveIcons className="dropdown-icon" />}{' '}
                                      {department.isActive === true ? 'Archive department' : 'Unarchive department'}
                                    </Dropdown.Item>
                                    <ArchivedModal
                                      show={modalShow}
                                      onHide={() => setModalShow(false)}
                                      setModalShow={setModalShow}
                                      setIsMenuOpen={setIsMenuOpen}
                                      department={department}
                                      departmentListState={departmentListState}
                                      fetchDepartmentData={fetchDepartmentData}
                                      archiveError={archiveError}
                                      setArchiveError={setArchiveError}
                                      customSize={'md'}
                                    />
                                  </div>
                                )}
                              </DropdownButton>
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>
    </LoadingScreen>
  );
};

export default DepartmentListTable;
