import { Option } from '@attendance-web-app/global-state/reducers/attendanceReducer.d';
import { useEffect, useState } from 'react';
import { useSchedulerModal } from '../hooks/useSchedulerModalHook';
import CustomDropDownSelect from './CustomDropDownSelect';
import SchedulerDaysSelector from './SchedulerDaysSelector';
import SchedulerModal from './SchedulerModal';
import SchedulerStatusItem from './SchedulerStatusitem';
import SchedulerTitle from './SchedulerTitle';
import { daysOfWeek, hoursOfDay, minutesInterval } from './data';

interface SchedulerOptionsProps {
  isSchedulerOpen: boolean;
  onSchedulerClickHandler: () => void;
}
interface schedulerModalOptionsProps {
  selectedStartHour: Option;
  selectedEndHour: Option;
  selectedMinutes: Option;
  selectedStartOfWeek: Option;
  selectedEndOfWeek: Option;
}

export const filterLabelByValueAndArray = (value: number, arr: { value: any; label: string }[]) => {
  const foundLabel = arr.find(item => Number(item.value) === value);
  return foundLabel ? foundLabel.label : null;
};
const SchedulerModalContainer = ({ isSchedulerOpen, onSchedulerClickHandler }: SchedulerOptionsProps) => {
  const { statusData, changeSchedulerTime, refetchSchedulerStatus } = useSchedulerModal(onSchedulerClickHandler);
  const { schedulerTiming } = statusData?.data ?? {};
  const [schedulerModalOptions, setSchedulerModalOptions] = useState<schedulerModalOptionsProps>({
    selectedStartHour: {
      value: schedulerTiming?.startHourOfDay ?? '',
      label: filterLabelByValueAndArray(schedulerTiming?.startHourOfDay, hoursOfDay) ?? ''
    },
    selectedEndHour: {
      value: schedulerTiming?.endHourOfDay ?? '',
      label: filterLabelByValueAndArray(schedulerTiming?.endHourOfDay, hoursOfDay) ?? ''
    },
    selectedMinutes: {
      value: schedulerTiming?.everyMinOfHour ?? '',
      label: filterLabelByValueAndArray(schedulerTiming?.everyMinOfHour, minutesInterval) ?? ''
    },
    selectedStartOfWeek: {
      value: schedulerTiming?.startDayOfWeek ?? '',
      label: filterLabelByValueAndArray(schedulerTiming?.startDayOfWeek, daysOfWeek) ?? ''
    },
    selectedEndOfWeek: {
      value: schedulerTiming?.endDayOfWeek ?? '',
      label: filterLabelByValueAndArray(schedulerTiming?.endDayOfWeek, daysOfWeek) ?? ''
    }
  });
  useEffect(() => {
    if (schedulerTiming) {
      setSchedulerModalOptions({
        selectedStartHour: {
          value: schedulerTiming?.startHourOfDay ?? '',
          label: filterLabelByValueAndArray(schedulerTiming?.startHourOfDay, hoursOfDay) ?? ''
        },
        selectedEndHour: {
          value: schedulerTiming?.endHourOfDay ?? '',
          label: filterLabelByValueAndArray(schedulerTiming?.endHourOfDay, hoursOfDay) ?? ''
        },
        selectedMinutes: {
          value: schedulerTiming?.everyMinOfHour ?? '',
          label: filterLabelByValueAndArray(schedulerTiming?.everyMinOfHour, minutesInterval) ?? ''
        },
        selectedStartOfWeek: {
          value: schedulerTiming?.startDayOfWeek ?? '',
          label: filterLabelByValueAndArray(schedulerTiming?.startDayOfWeek, daysOfWeek) ?? ''
        },
        selectedEndOfWeek: {
          value: schedulerTiming?.endDayOfWeek ?? '',
          label: filterLabelByValueAndArray(schedulerTiming?.endDayOfWeek, daysOfWeek) ?? ''
        }
      });
    }
  }, [schedulerTiming]);
  const updateSchedulerOptions = () => {
    const startHourOfDay: any = schedulerModalOptions.selectedStartHour.value;
    const endHourOfDay: any = schedulerModalOptions.selectedEndHour.value;
    const everyMinOfHour: any = schedulerModalOptions.selectedMinutes.value;
    const startDayOfWeek: any = schedulerModalOptions.selectedStartOfWeek.value;
    const endDayOfWeek: any = schedulerModalOptions.selectedEndOfWeek.value;
    const payload = {
      startHourOfDay: startHourOfDay && parseInt(startHourOfDay),
      endHourOfDay: endHourOfDay && parseInt(endHourOfDay),
      everyMinOfHour: everyMinOfHour && parseInt(everyMinOfHour),
      startDayOfWeek: startDayOfWeek && parseInt(startDayOfWeek),
      endDayOfWeek: endDayOfWeek && parseInt(endDayOfWeek)
    };

    changeSchedulerTime(payload);
  };
  const onChangeSelectHandler = (options: Option, type: string) => {
    switch (type) {
      case 'START_HOUR':
        setSchedulerModalOptions({
          ...schedulerModalOptions,
          selectedStartHour: options
        });
        break;
      case 'END_HOUR':
        setSchedulerModalOptions({
          ...schedulerModalOptions,
          selectedEndHour: options
        });
        break;
      case 'START_OF_WEEK':
        setSchedulerModalOptions({
          ...schedulerModalOptions,
          selectedStartOfWeek: options
        });
        break;
      case 'MINUTES':
        setSchedulerModalOptions({
          ...schedulerModalOptions,
          selectedMinutes: options
        });
        break;
      case 'END_OF_WEEK':
        setSchedulerModalOptions({
          ...schedulerModalOptions,
          selectedEndOfWeek: options
        });
        break;
      default:
        break;
    }
  };

  const isDisabled = () => {
    return !(
      schedulerModalOptions.selectedStartHour.value ||
      schedulerModalOptions.selectedEndHour.value ||
      schedulerModalOptions.selectedEndOfWeek.value ||
      schedulerModalOptions.selectedStartOfWeek.value ||
      schedulerModalOptions.selectedMinutes.value
    );
  };

  return (
    <SchedulerModal
      showModal={isSchedulerOpen}
      handleHideModal={onSchedulerClickHandler}
      handleMenuOpen={onSchedulerClickHandler}
      modalTitle="Refresh Scheduler"
      schedulerRunning={statusData && statusData?.data?.running}
      footerComponent={
        <>
          <button className="btn-tertiary" onClick={onSchedulerClickHandler}>
            Cancel
          </button>
          <button className="btn " disabled={isDisabled()} onClick={updateSchedulerOptions}>
            Update Scheduler
          </button>
        </>
      }
    >
      <div className="p-2">
        <div className="schedulerStatusContainer">
          <p className="schedulerStatusName">Scheduler Status</p>
          <div className="d-flex justify-content-between">
            <SchedulerStatusItem
              iconName="right"
              executedDate={statusData?.data?.lastExecution}
              executedTitle="Last Executed On"
            />
            <SchedulerStatusItem
              iconName="left"
              executedDate={statusData?.data?.nextExecution}
              executedTitle="Next Execution On"
            />
          </div>
        </div>
      </div>
      {
        <>
          <SchedulerDaysSelector
            start={statusData?.data?.schedulerTiming?.startDayOfWeek}
            end={statusData?.data?.schedulerTiming?.endDayOfWeek}
            onChangeSelectHandler={onChangeSelectHandler}
          />

          <div className="d-flex justify-content-between mt-4">
            <div className="w-50" style={{ marginRight: 12 }}>
              <CustomDropDownSelect
                title="Start hour"
                options={hoursOfDay}
                selectedOptions={schedulerModalOptions.selectedStartHour}
                onChangeSelectHandler={options => onChangeSelectHandler(options, 'START_HOUR')}
              />
            </div>
            <div className="w-50" style={{ marginLeft: 12 }}>
              <CustomDropDownSelect
                title="End hour"
                options={hoursOfDay}
                selectedOptions={schedulerModalOptions.selectedEndHour}
                onChangeSelectHandler={options => onChangeSelectHandler(options, 'END_HOUR')}
              />
            </div>
          </div>
          <div className="pt-4">
            <SchedulerTitle
              title="Repeat Every"
              subTitle="Select the interval (in minutes) for the scheduler to repeat."
            />
            <div className="w-100">
              <CustomDropDownSelect
                title="Select Minutes"
                options={minutesInterval}
                selectedOptions={schedulerModalOptions.selectedMinutes}
                onChangeSelectHandler={options => onChangeSelectHandler(options, 'MINUTES')}
                isAbsolute={false}
              />
            </div>
          </div>
        </>
      }
    </SchedulerModal>
  );
};

export default SchedulerModalContainer;
