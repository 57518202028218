import { Icons } from '@attendance-web-app/assets/Icons/Icons';
import { useCustomModalHook } from '@attendance-web-app/components/Common/hooks/useCustomModalHook';
import { IDepartmentList } from '@attendance-web-app/types/department/type';
import { Icon } from '@iconify/react';
import React from 'react';
import { Alert } from 'react-bootstrap';
import UploadErrorModal from '../_EmployeeList/UploadErrorModal';
import ViewEmployeeListInviteModal from './ViewEmployeeListInviteModal';
import { useViewEmployeeListHeaderHook } from './hooks/ useViewEmployeeListHeaderHook';

interface IViewEmployeeListHeaderProps {
  departmentDetails: IDepartmentList;
  searchKeyword: string;
  setSearchKeyword: React.Dispatch<React.SetStateAction<string>>;
}

const ViewEmployeeListHeader = ({ searchKeyword, setSearchKeyword }: IViewEmployeeListHeaderProps) => {
  const {
    handleOpenModal,
    handleCloseModal,
    onSearchChange,
    handleClearInput,
    setCreatedMsg,
    setUploadErrorMsg,
    setUploadUpdatedMsg,
    setUploadErrorData,
    setUploadedData,
    createdMsg,
    uploadErrorMsg,
    showModal,
    uploadErrorData,
    uploadedData,
    uploadUpdatedMsg
  } = useViewEmployeeListHeaderHook(setSearchKeyword);
  const { modalShow, handleCustomCloseModal, handleCustomOpenModal } = useCustomModalHook();

  return (
    <>
      <div className="employee-list-header">
        <div className="employee-list-search view-employee-list-bar">
          <form onSubmit={e => e.preventDefault()}>
            <div className="header-search-bar">
              <div className="search-bar">
                <Icons.SearchIcon
                  height={20}
                  fill="#595959"
                  className="search-icon "
                  style={{ verticalAlign: 'top' }}
                />
                <input
                  className="body-large-regular"
                  type="text"
                  placeholder="Search"
                  value={searchKeyword}
                  onChange={onSearchChange}
                />
                {searchKeyword && (
                  <Icons.CrossIcon height={20} fill="#595959" className="clear-icon" onClick={handleClearInput} />
                )}
              </div>
            </div>
          </form>
        </div>
        <div className="employee-list-invite">
          <button className="btn invite-button body-large-regular" onClick={handleCustomOpenModal}>
            <Icons.PlusIcon height={16} width={16} />
            <span className="body-regular invite-text"> Invite employee </span>
          </button>
          <ViewEmployeeListInviteModal
            show={modalShow}
            onHide={handleCustomCloseModal}
            setCreatedMsg={setCreatedMsg}
            setUploadErrorMsg={setUploadErrorMsg}
            setUploadUpdatedMsg={setUploadUpdatedMsg}
            setUploadErrorData={setUploadErrorData}
            setUploadedData={setUploadedData}
          />
        </div>
      </div>
      {createdMsg && (
        <Alert
          variant="success"
          dismissible
          onClose={() => setCreatedMsg('')}
          className="d-flex justify-content-between mx-3 body-large-regular"
          style={{ width: '97%' }}
        >
          <span>
            <Icons.Check height={24} /> &nbsp; {createdMsg}
          </span>
          <span className="upload-error-msg">
            {uploadErrorMsg} &nbsp;
            {<Icon icon="material-symbols:circle" width="4px" height="4px" color="red" style={{ margin: '0 5px' }} />}
            &nbsp; {uploadUpdatedMsg} &nbsp; &nbsp;
            <span className="btn-inline alert-view-details" onClick={handleOpenModal}>
              View details
            </span>
            {showModal && (
              <UploadErrorModal
                handleCloseModal={handleCloseModal}
                showModal={showModal}
                uploadErrorData={uploadErrorData}
                uploadedData={uploadedData}
              />
            )}
          </span>
        </Alert>
      )}
    </>
  );
};

export default ViewEmployeeListHeader;
