import { Icons } from '@attendance-web-app/assets/Icons/Icons';
import { useAdvanceFilterSidebar } from '@attendance-web-app/components/_pages/_header/hooks/useAdvanceFilterSidebar';
import {
  saveSpendingListParamsOnly,
  spendingListSuccess
} from '@attendance-web-app/global-state/actions/spendingListAction';
import { useSpendingListContext } from '@attendance-web-app/global-state/context/SpendingListContext';
import { Option } from '@attendance-web-app/global-state/reducers/employeeReducer.d';
import { getdepartmentList as getDepartmentList } from '@attendance-web-app/services/department.services';
import { getDesignationOptionType } from '@attendance-web-app/services/designation.service';
import { ISpendingTransaction, getSpendingList } from '@attendance-web-app/services/spendingList.services';
import { getVendorListApi } from '@attendance-web-app/services/vendor.services';
import { colors } from '@attendance-web-app/theme/colors';
import { IHandlePaginateClick, selectedOptionType } from '@attendance-web-app/types';
import { ISpendingViewCardProps } from '@attendance-web-app/types/expensesOverview/spendingType';
import {
  formatAmountToStandardFormat,
  joinArrayWithComma,
  prepareSpendingListToDownload
} from '@attendance-web-app/utils';
import { handleErrorMessage } from '@attendance-web-app/utils/errorMessage';
import useCustomListApi from '@attendance-web-app/utils/hooks/useCustomListApi';
import { format } from 'date-fns';
import { useState } from 'react';
import useSpendingViewCardApi from './useSpendingViewCardApi';
export const spendingViewCards: Array<ISpendingViewCardProps> = [
  {
    title: 'Total Balance',
    icon: <Icons.topUpIcon />,
    value: '',
    percentageChangeInValue: 4.65
  },
  {
    title: 'Total Balance Spend',
    icon: <Icons.spendBalanceIcon />,
    value: '',
    percentageChangeInValue: 4.65
  },
  {
    title: 'Total Employee',
    icon: <Icons.Users height={26} width={26} stroke={colors.colorP02} />,
    value: '',
    percentageChangeInValue: -4.65
  }
];
export const statusTypes: Option[] = [
  {
    label: 'Active',
    value: 'true'
  },
  {
    label: 'Inactive',
    value: 'false'
  }
];

export const useSpendingListPageHook = () => {
  const todayDate = format(new Date(), 'yyyy-MM-dd');
  const [selectedSpendingTransactions, setSelectedSpendingTransactions] = useState<ISpendingTransaction[]>([]);

  // context state
  const { spendingListState, spendingListDispatch } = useSpendingListContext();
  const {
    paramsValue: contextParams,
    selectedSpendingList,
    departmentTypes,
    designationTypes,
    vendors
  } = spendingListState;
  const spendingListData = selectedSpendingList && selectedSpendingList?.length > 0 ? selectedSpendingList : [];

  const departmentSelectedOptionValue = spendingListState.departmentSelectedOption?.value ?? [];
  const designationSelectedOptionValue = spendingListState.designationSelectedOption?.value ?? [];
  const vendorsSelectedOptionValue = spendingListState.vendorsSelectedOption?.value ?? [];
  const spendingDataToDownload = prepareSpendingListToDownload(spendingListData);
  // Internal States.
  const [selectedOptions, setSelectedOptions] = useState({
    selectedDate: '',
    departmentSelectedOption: [],
    designationSelectedOption: [],
    vendorsSelectedOption: []
  });

  // custom hooks
  const { refetchQuery: refetchDepartmentTypes } = useCustomListApi({
    queryKey: ['departmentList'],
    getApiFn: getDepartmentList,
    enabled: false,
    params: {}
  });

  const { refetchQuery: refetchDesignationTypes } = useCustomListApi({
    queryKey: ['designationList'],
    getApiFn: getDesignationOptionType,
    enabled: false,
    params: {}
  });

  const {
    data: spendingList,
    refetchQuery: refetchSpendingList,
    isLoading: spendingListIsLoading,
    isRefetching: spendingListIsRefetching
  } = useCustomListApi({
    queryKey: ['spendingList'],
    getApiFn: getSpendingList,
    enabled: false,
    params: {}
  }) as any;
  const spendingListLoading = spendingListIsLoading || spendingListIsRefetching;
  const { data: vendorList, refetchQuery: refetchVendorList } = useCustomListApi({
    queryKey: ['vendorList'],
    getApiFn: getVendorListApi,
    enabled: false,
    params: {}
  });

  const { data, isLoading, isSuccess, refetchQuery } = useSpendingViewCardApi({
    date: todayDate
  });

  const { showAdvancedFilter, openAdvanceFilter, toggleAdvancedFilter, cancelAdvancedFilter } =
    useAdvanceFilterSidebar();

  // data preparation for card
  const spendingViewCardsOutput: Array<ISpendingViewCardProps> = spendingViewCards.map((item, index) => {
    if (index === 0) {
      return {
        ...item,
        value: `Rs. ${formatAmountToStandardFormat(data['totalBalanceTopUp'])}` ?? 'Rs. 0',
        percentageChangeInValue: data['percentChangeInBalanceTopUp']?.toFixed(2) ?? 0
      };
    } else if (index === 1) {
      return {
        ...item,
        value: `Rs. ${formatAmountToStandardFormat(data['totalBalanceSpend'])}` ?? 'Rs. 0',
        percentageChangeInValue: data['percentChangeInBalanceSpend']?.toFixed(2) ?? 0
      };
    } else {
      return {
        ...item,
        value: data['totalEmployee'],
        percentageChangeInValue: data['percentChangeInEmployee']?.toFixed(2) ?? 0
      };
    }
  });
  // action listeners
  const saveOptionsTypesToSpendingContextFromServer = (
    type: 'departmentTypes' | 'designationTypes' | 'vendors',
    options: { label: string; value: string; departmentIdNo?: string; departmentName?: string }[]
  ) => {
    spendingListDispatch(
      spendingListSuccess({
        [type]: options
      })
    );
  };
  const actualShowAdvancedFilterHandler = () => {
    if (!showAdvancedFilter) {
      const params = {
        searchText: '',
        limit: 100,
        page: 1
      };
      refetchDepartmentTypes(params)
        .then((response: any) => {
          const department = response?.data?.departments.map((departmentType: any) => {
            return {
              label: departmentType.name,
              value: departmentType.id,
              departmentIdNo: departmentType.departmentIdNo
            };
          });
          saveOptionsTypesToSpendingContextFromServer('departmentTypes', department);
        })
        .catch(err => {
          handleErrorMessage(err);
        });
      refetchVendorList({})
        .then((vendorList: any[]) => {
          const vendorOptions = vendorList.map((vendor: any) => {
            return {
              label: vendor.vendorName,
              value: vendor.vendorName
            };
          });
          saveOptionsTypesToSpendingContextFromServer('vendors', vendorOptions);
        })
        .catch(err => handleErrorMessage(err));
    }
    openAdvanceFilter();
  };

  const spendingCommonHandler = (params: any, actionPayload: any, clearAll = false) => {
    const payload = {
      ...contextParams,
      ...params
    };
    refetchSpendingList(payload);
    if (!clearAll) {
      spendingListDispatch(spendingListSuccess(actionPayload));
      spendingListDispatch(
        saveSpendingListParamsOnly({
          ...params
        })
      );
    }
  };
  // when user change search input
  const onChangeSearchHandler = (searchText: string) => {
    const formattedDate = selectedOptions.selectedDate || new Date();
    const payload = {
      searchText,
      page: 1,
      date: format(new Date(formattedDate), 'yyyy-MM-dd')
    };

    const actionPayload = {};
    spendingCommonHandler(payload, actionPayload);
  };

  const changingSelectedStateHelperFunction = (
    selectedOptionsTitle: 'departmentSelectedOption' | 'designationSelectedOption' | 'vendorsSelectedOption',
    options: any
  ) => {
    setSelectedOptions({
      ...selectedOptions,
      [selectedOptionsTitle]: options
    });
  };

  const saveSelectedDesignationDataToAttendanceContext = (selectedDesignations: any) => {
    spendingListDispatch(
      spendingListSuccess({
        designationSelectedOption: {
          selected: 'DESIGNATION_TYPE',
          value: selectedDesignations
        }
      })
    );
  };

  const fetchDesignationOfSelectedDepartmentForBothChipAndFilter = ({
    selectedDepartments,
    options,
    isChip = false,
    isCallFromHod = false
  }: {
    selectedDepartments: any;
    options: any;
    isChip?: boolean;
    isCallFromHod?: boolean;
  }) => {
    // if no department is selected remove designation data amd selected designation context
    if (!selectedDepartments) {
      saveOptionsTypesToSpendingContextFromServer('designationTypes', []);
      saveSelectedDesignationDataToAttendanceContext([]);
      setSelectedOptions(prev => ({
        ...prev,
        designationSelectedOption: []
      }));

      if (isChip) {
        const designationPayload = { designations: '' } as any;
        spendingListDispatch(saveSpendingListParamsOnly({ ...designationPayload }));
      }

      if (!isCallFromHod) {
        setSelectedOptions(prev => ({
          ...prev,
          departmentSelectedOption: options
        }));
      }
    } else {
      refetchDesignationTypes({ department: selectedDepartments })
        .then((response: any) => {
          const designationsOfSelectedDepartments = response?.data?.map((designation: { id: number; name: string }) => {
            return {
              label: designation.name,
              value: designation.id
            };
          });

          // remove selected designation when its associated department is unselected
          const filteredSelectedDesignations = designationsOfSelectedDepartments.filter((designation: any) =>
            selectedOptions.designationSelectedOption.some(
              (selectedDesignation: any) => selectedDesignation.value === designation.value
            )
          );
          saveOptionsTypesToSpendingContextFromServer('designationTypes', designationsOfSelectedDepartments);

          setSelectedOptions(prev => ({
            ...prev,
            designationSelectedOption: filteredSelectedDesignations
          }));
          if (!isCallFromHod) {
            setSelectedOptions(prev => ({
              ...prev,
              departmentSelectedOption: options
            }));
          }

          if (isChip) {
            const selectedDesignationParams = joinArrayWithComma(filteredSelectedDesignations, 'label');
            const designationPayload = { designations: selectedDesignationParams } as any;
            spendingListDispatch(saveSpendingListParamsOnly({ ...designationPayload }));
            saveSelectedDesignationDataToAttendanceContext(filteredSelectedDesignations);
          }
        })
        .catch(err => {
          handleErrorMessage(err);
        });
    }
  };

  const onChangeSelectHandler = (type: string, options: any) => {
    const dateActionPayload = {
      departments: spendingListState.departmentTypes
    };

    switch (type) {
      case selectedOptionType.DATE:
        const date = options ? format(new Date(options), 'yyyy-MM-dd') : format(new Date(), 'yyyy-MM-dd');
        setSelectedOptions({
          ...selectedOptions,
          selectedDate: date
        });
        const datePayload = {
          date: date,
          page: 1
        };
        spendingCommonHandler(datePayload, dateActionPayload);
        refetchQuery({
          date
        });
        break;
      case selectedOptionType.DEPARTMENT_TYPE:
        const selectedDepartments = joinArrayWithComma(options, 'value');
        fetchDesignationOfSelectedDepartmentForBothChipAndFilter({ selectedDepartments, options });
        break;
      case selectedOptionType.DESIGNATION_TYPE:
        changingSelectedStateHelperFunction('designationSelectedOption', options);
        break;
      case selectedOptionType.VENDORS:
        changingSelectedStateHelperFunction('vendorsSelectedOption', options);
        break;
      default:
        break;
    }
  };

  const chipChangeHandlerHelper = (
    selectedOptionsTitle: 'departmentSelectedOption' | 'designationSelectedOption' | 'vendorsSelectedOption',
    options: any,
    paramTitle: 'departments' | 'designations' | 'vendors',
    selectionOptionType: string
  ) => {
    // setting state
    changingSelectedStateHelperFunction(selectedOptionsTitle, options);

    // setting context
    const payload = {
      ...contextParams,
      [paramTitle]: joinArrayWithComma(options, 'label')
    };
    const actionPayload = {
      ...spendingListState,
      [selectedOptionsTitle]: {
        selected: selectionOptionType,
        value: options
      }
    };

    // fetch
    spendingCommonHandler(payload, actionPayload);
  };
  // when user change any of the value of selected options on chip.
  const onChipChangeSelectHandler = (type: string, options: any) => {
    switch (type) {
      case selectedOptionType.DEPARTMENT_TYPE:
        chipChangeHandlerHelper('departmentSelectedOption', options, 'departments', selectedOptionType.DEPARTMENT_TYPE);
        const selectedDepartments = joinArrayWithComma(options, 'value');
        fetchDesignationOfSelectedDepartmentForBothChipAndFilter({
          selectedDepartments,
          options,
          isChip: true
        });
        break;
      case selectedOptionType.DESIGNATION_TYPE:
        chipChangeHandlerHelper(
          'designationSelectedOption',
          options,
          'designations',
          selectedOptionType.DESIGNATION_TYPE
        );
        break;
      case selectedOptionType.VENDORS:
        chipChangeHandlerHelper('vendorsSelectedOption', options, 'vendors', selectedOptionType.VENDORS);
        break;

      default:
        break;
    }
  };

  const onApplyHandler = () => {
    const payload = {
      ...contextParams,
      page: 1,
      departments: joinArrayWithComma(selectedOptions.departmentSelectedOption, 'label'),
      designations: joinArrayWithComma(selectedOptions.designationSelectedOption, 'label'),
      vendors: joinArrayWithComma(selectedOptions.vendorsSelectedOption, 'label')
    };
    const actionPayload = {
      ...spendingListState,
      departmentSelectedOption: {
        selected: selectedOptionType.DEPARTMENT_TYPE,
        value: selectedOptions.departmentSelectedOption
      },
      designationSelectedOption: {
        selected: selectedOptionType.DESIGNATION_TYPE,
        value: selectedOptions.designationSelectedOption
      },
      vendorsSelectedOption: {
        selected: selectedOptionType.VENDORS,
        value: selectedOptions.vendorsSelectedOption
      }
    } as any;
    spendingCommonHandler(payload, actionPayload);
  };

  const onResetHandler = (type: string) => {
    switch (type) {
      case selectedOptionType.DEPARTMENT_TYPE:
        const departmentPayload = {
          departments: '',
          designations: ''
        };
        const departmentActionPayload = {
          departmentSelectedOption: {
            selected: '',
            value: []
          },
          designationSelectedOption: {
            selected: '',
            value: []
          }
        };
        spendingCommonHandler(departmentPayload, departmentActionPayload);
        setSelectedOptions({
          ...selectedOptions,
          departmentSelectedOption: []
        });
        break;
      case selectedOptionType.DESIGNATION_TYPE:
        const designationPayload = {
          designations: ''
        };
        const designationActionPayload = {
          designationSelectedOption: {
            selected: '',
            value: []
          }
        };
        spendingCommonHandler(designationPayload, designationActionPayload);
        setSelectedOptions({
          ...selectedOptions,
          designationSelectedOption: []
        });
        break;
      case selectedOptionType.VENDORS:
        const vendorsPayload = {
          vendors: ''
        };
        const vendorsActionPayload = {
          vendorsSelectedOption: {
            selected: '',
            value: []
          }
        };
        spendingCommonHandler(vendorsPayload, vendorsActionPayload);
        setSelectedOptions({
          ...selectedOptions,
          vendorsSelectedOption: []
        });
        break;
      default:
        break;
    }
  };
  const resetSelectedOptionsFromAdvanceFilter = () => {
    setSelectedOptions({
      selectedDate: '',
      departmentSelectedOption: [],
      designationSelectedOption: [],
      vendorsSelectedOption: []
    });
  };

  const onClearAllFilter = () => {
    const payload = {
      departments: '',
      designations: '',
      vendors: '',
      searchText: '',
      limit: 10,
      params: {},
      page: 1
    };
    const actionPayload = {
      departmentSelectedOption: {
        selected: '',
        value: []
      },
      designationSelectedOption: {
        selected: '',
        value: []
      },
      vendorsSelectedOption: {
        selected: '',
        value: []
      }
    };
    spendingCommonHandler(payload, actionPayload);
    resetSelectedOptionsFromAdvanceFilter();
  };

  // when user click the pagination item
  const handlePaginateClick = (event: IHandlePaginateClick) => {
    const limit = spendingListState.paramsValue?.limit ?? 10;
    const page = event.selected + 1;
    const payload = {
      limit,
      page
    };
    const actionPayload = {
      ...contextParams,
      limit,
      page
    };
    refetchSpendingList(actionPayload);
    spendingListDispatch(
      saveSpendingListParamsOnly({
        ...payload
      } as any)
    );
  };

  // when user click the pagination item
  const handleLimitChange = (limit: number) => {
    const payload = {
      limit,
      page: 1
    };
    const actionPayload = {
      ...contextParams,
      ...payload
    };
    refetchSpendingList(actionPayload);
    spendingListDispatch(
      saveSpendingListParamsOnly({
        ...payload
      } as any)
    );
  };

  return {
    data,
    isLoading,
    isSuccess,
    refetchQuery,
    showAdvancedFilter,
    toggleAdvancedFilter,
    cancelAdvancedFilter,
    spendingViewCardsOutput,
    onChangeSelectHandler,
    selectedOptions,
    onChangeSearchHandler,
    onApplyHandler,
    spendingList,
    departmentTypes,
    designationTypes,
    vendors,
    handleLimitChange,
    handlePaginateClick,
    selectedSpendingTransactions,
    setSelectedSpendingTransactions,
    actualShowAdvancedFilterHandler,
    onChipChangeSelectHandler,
    spendingListState,
    onResetHandler,
    onClearAllFilter,
    departmentSelectedOptionValue,
    designationSelectedOptionValue,
    vendorsSelectedOptionValue,
    spendingListLoading,
    openAdvanceFilter
  };
};
