import getDesignationServices from '@attendance-web-app/services/designation.service';
import { IEditDesignationModalProps } from '@attendance-web-app/types/designation/type';

import Alert from '@attendance-web-app/components/Common/Alert';
import CustomModal from '@attendance-web-app/components/Common/CustomModal';
import DesignationForm from './DesignationForm';

const EditDesignationModal = ({
  departmentIdNo,
  fetchDesignationData,
  onHide,
  setIsMenuOpen,
  show,
  designationData
}: IEditDesignationModalProps) => {
  const handleEdit = async (editableData: { id: number; designation: string }) => {
    try {
      const { id, designation } = editableData;
      await getDesignationServices.updateDesignation(id, designation);

      const loggedUser = JSON.parse(localStorage.getItem('admin_user') as string);
      Alert('success', `Designation has been updated successfully.`);
      if (loggedUser.department.departmentIdNo === departmentIdNo && loggedUser.designation.id === id) {
        loggedUser.designation.name = designation;
        localStorage.setItem('admin_user', JSON.stringify(loggedUser));
      }
      fetchDesignationData();
      onHide();
      setIsMenuOpen(false);
    } catch (error: any) {
      Alert('error', `${error.response.data.message ?? 'Error while updating designation'}`);
      throw error;
    }
  };

  return (
    <CustomModal
      showModal={show}
      handleHideModal={onHide}
      modalTitle="Edit Designation"
      footerComponent={
        <>
          <button
            onClick={() => {
              onHide();
              setIsMenuOpen(false);
            }}
            className="btn-tertiary"
          >
            Cancel
          </button>
          <button form="invite-designation-form" className="btn">
            Edit Designation
          </button>
        </>
      }
    >
      <DesignationForm handleEdit={handleEdit} initialDesignation={designationData?.name} id={designationData?.id} />
    </CustomModal>
  );
};

export default EditDesignationModal;
