import { Link } from 'react-router-dom';
import AvatarName from './AvatarName';

interface CustomPopoverProps {
  id: string | null;
  photo: string;
  name: string;
  department: string;
  designation: string;
  phoneNumber: number | null;
  email: string;
}

const CustomPopover = ({ id, name, photo, department, designation, phoneNumber, email }: CustomPopoverProps) => {
  return (
    <div className="customPopover">
      <div className="d-flex mb-1 ">
        <div className="mt-0.5">
          {photo ? (
            <img alt={name} src={photo} height="32px" width="32px" className="round-image" />
          ) : (
            <AvatarName name={name ?? ''} />
          )}
        </div>
        <div className="flex-shrink-1 ms-2 pb-1">
          <div className="body-regular-semibold mb-2">
            {name} - {id}
          </div>
          <Link to={`/records/${id}`} className="body-regular-underline view-profile">
            View profile
          </Link>
        </div>
      </div>
      <div className="customPopover__subContainer">
        <div>
          <div>
            <span className="body-small-semibold ">Department:</span>{' '}
            <span className="body-small-regular">{department}</span>{' '}
          </div>
          <div>
            <span className="body-small-semibold ">Designation:</span>{' '}
            <span className="body-small-regular">{designation}</span>{' '}
          </div>
          <div>
            <span className="body-small-semibold ">Phone Number:</span>{' '}
            <span className="body-small-regular">{phoneNumber}</span>{' '}
          </div>
          <div>
            <span className="body-small-semibold ">Email:</span> <span className="body-small-regular">{email}</span>{' '}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomPopover;
