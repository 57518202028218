import LoadingScreen from '@attendance-web-app/components/Common/LoadingScreen';
import { useState } from 'react';

import { Icons } from '@attendance-web-app/assets/Icons/Icons';
import Alert from '@attendance-web-app/components/Common/Alert';
import getDesignationServices from '@attendance-web-app/services/designation.service';
import { IDesignation, IViewDesignationListTableProps } from '@attendance-web-app/types/designation/type';
import { Badge, Dropdown, DropdownButton } from 'react-bootstrap';
import DesignationArchiveModal from './DesignationArchiveModal';
import EditDesignationModal from './EditDesignationModal';

const DesignationListTable = ({
  designationList,
  filteredDesignationData,
  fetchDesignationData,
  isLoading,
  searchKeyword,
  departmentIdNo
}: IViewDesignationListTableProps) => {
  const [modalShow, setModalShow] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const [selectedId, setSelectedId] = useState<number>();
  const [modalEditShow, setModalEditShow] = useState(false);
  const [archiveError, setArchiveError] = useState<string>('');
  const hasFilteredData = filteredDesignationData.some(designation => designation.name !== '');

  const dataMap = hasFilteredData && searchKeyword.length !== 0 ? filteredDesignationData : designationList;

  const handleOpen = (id: number) => {
    setModalShow(false);
    setSelectedId(id);
    setIsMenuOpen(!isMenuOpen);
  };

  const requiredDesignation = (designation: IDesignation) => {
    const foundDesignation = designationList.find((data: IDesignation) => data.id === designation.id);
    return foundDesignation || { id: 0, name: '' };
  };

  const handleArchive = async (designation: IDesignation) => {
    try {
      const loggedUser = JSON.parse(localStorage.getItem('admin_user') as string);

      if (loggedUser.designation.name === designation.name && loggedUser.department.departmentIdNo === departmentIdNo) {
        setArchiveError('Invalid archive operation by logged in user');
        return console.error('Invalid archive operation by logged in user');
      }
      setModalShow(false);
      designation.isArchived = !designation.isArchived;
      await getDesignationServices.updateDesignation(designation.id, designation.isArchived);
      Alert('success', `Designation has been ${designation.isArchived ? 'archived' : 'unarchive'} successfully.`);
    } catch (error: any) {
      Alert('error', `${error.response.data.message ?? 'Something went wrong when archiving designation'}`);
      throw error;
    }
  };

  return (
    <LoadingScreen isLoading={isLoading}>
      <div className="col-12">
        {designationList?.length <= 0 || (!hasFilteredData && searchKeyword.length > 0) ? (
          <div className="empty-message body-large-regular">No data to display</div>
        ) : (
          <div className="list-wrapper-designation">
            <div className="table-responsive department-list-table">
              <table className="table">
                <thead className="thead body-small-semibold designation-thead">
                  <tr className="department-header">
                    <th>DESIGNATION</th>
                    <th>TOTAL EMPLOYEES</th>
                  </tr>
                </thead>

                <tbody className="tbody">
                  {dataMap?.map(designation => (
                    <tr key={designation.id}>
                      <td className="align-middle py-3">
                        <span className="body-small-regular">{designation.name}</span>
                      </td>

                      <td className="align-middle p-0">
                        <div className="d-flex align-middle justify-content-between ms-2 " style={{ gap: '20px' }}>
                          <div className="align-middle body-small-regular department-list-text m-auto ms-1">
                            {designation.employeeCount}
                          </div>

                          <div className="d-flex justify-content-around department-option">
                            {designation.isArchived ? (
                              <Badge className="archive-badge body-small-regular mt-3">Archived</Badge>
                            ) : (
                              <div className="archive-badge" style={{ visibility: 'hidden' }}></div>
                            )}
                            <DropdownButton
                              drop="start"
                              id="dropdown-basic-button"
                              title={<Icons.OptionIcon height={20} onClick={() => handleOpen(designation.id)} />}
                              className="options-icon-designation mb-4"
                            >
                              {selectedId === designation.id && (
                                <>
                                  <div className="edit-designation body-small-regular">
                                    <Dropdown.Item
                                      onClick={() => !designation.isArchived && setModalEditShow(true)}
                                      className={designation.isArchived ? 'disable-designation' : 'editDesignation'}
                                      disabled={designation.isArchived}
                                    >
                                      {<Icons.EditIcon className="dropdown-icon" />}Edit designation
                                    </Dropdown.Item>
                                    <EditDesignationModal
                                      departmentIdNo={departmentIdNo}
                                      designationData={requiredDesignation(designation)}
                                      fetchDesignationData={fetchDesignationData}
                                      show={modalEditShow}
                                      onHide={() => setModalEditShow(false)}
                                      setIsMenuOpen={setIsMenuOpen}
                                    />
                                  </div>
                                  <Dropdown.Item
                                    onClick={() => {
                                      setModalShow(true);
                                    }}
                                    className="body-small-regular"
                                  >
                                    {<Icons.ArchiveIcons className="dropdown-icon" />}
                                    {designation.isArchived === false ? 'Archive designation' : 'Unarchive designation'}
                                  </Dropdown.Item>

                                  <DesignationArchiveModal
                                    show={modalShow}
                                    customSize={'md'}
                                    onHide={() => setModalShow(false)}
                                    handleArchive={handleArchive}
                                    designation={designation}
                                    archiveError={archiveError}
                                    setArchiveError={setArchiveError}
                                  />
                                </>
                              )}
                            </DropdownButton>
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>
    </LoadingScreen>
  );
};

export default DesignationListTable;
