import { SetTimePeriod } from '@attendance-web-app/global-state/actions/filtersAction';
import { useFiltersContext } from '@attendance-web-app/global-state/context/FiltersContext';
import { downloadIndividualCsv } from '@attendance-web-app/services/download.service';
import { DropdownOption } from '@attendance-web-app/types/employee/type';
import { useParams } from 'react-router-dom';
export const useRecordHeaderHook = (selectedAttendance: any) => {
  const { filtersDispatch, filtersState } = useFiltersContext();
  let { employeeId } = useParams();
  const attendanceDataToDownload = selectedAttendance?.map((data: any) => {
    return {
      EmployeeID: data.employeeInfo.employeeId,
      Name: data.employeeInfo.name,
      Email: data.employeeInfo.email,
      Department: data.employeeInfo.department,
      Day: data.day,
      Date: data.date,
      CheckInTime: data.checkInConverted,
      CheckOutTime: data.checkOutConverted,
      Designation: data.employeeInfo.designation.name,
      Supervisor: data.employeeInfo?.supervisor,
      Hod: data.employeeInfo?.hod,
      CheckInTimeRemarks: data.checkInConvertedRemarks,
      CheckOutTimeRemarks: data.checkOutConvertedRemarks
    };
  });
  if (!employeeId) {
    employeeId = String(JSON.parse(localStorage.getItem('admin_user') as string).employeeIdNo);
  }
  const { timePeriod } = filtersState;

  const Periodicity: DropdownOption[] = [
    { value: 'Weekly', label: 'Weekly' },
    { value: 'Monthly', label: 'Monthly' }
  ];

  function onSelection(_: string, selectedOption: DropdownOption) {
    filtersDispatch(SetTimePeriod(selectedOption.label));
  }

  const handleDownloadCsv = (employeeId: string) => {
    const date = new Date(filtersState.date).toISOString().slice(0, 10);
    const department = filtersState?.Department;
    const name = filtersState?.search;
    const period = filtersState?.timePeriod;

    downloadIndividualCsv(date, department, name, period, employeeId);
  };

  const getTimePeriod = () => {
    if (timePeriod === 'month') {
      return 'Monthly';
    } else {
      return 'Weekly';
    }
  };
  return {
    Periodicity,
    attendanceDataToDownload,
    onSelection,
    getTimePeriod,
    handleDownloadCsv
  };
};
